.gradient-card {
  align-items: flex-start;
  background: linear-gradient(180deg, rgb(100, 54, 149) 8.85%, rgb(23, 136, 195) 100%);
  border-radius: 12px;
  display: flex;
  flex-direction: column;
  gap: 16px;
  margin-top: 17px;
  padding: 24px 16px;
  position: relative;
  width: 335px;
}

.gradient-card.gradient-card-1 {
  background: linear-gradient(180deg, rgb(183, 39, 85) 8.85%, rgb(85, 72, 157) 100%);
  margin-top: 16px;
}

.gradient-card.gradient-card-2 {
  background: linear-gradient(180deg, rgb(65, 86, 166) 8.85%, rgb(0, 155, 223) 100%);
}

.gradient-card.gradient-card-3 {
  background: linear-gradient(180deg, rgb(100, 54, 149) 11.71%, rgb(65, 86, 166) 56.99%);
  margin-top: 16px;
}

.gradient-card .button-1 {
  background-color: white;
}

.gradient-card .button-2 {
  color: black;
}