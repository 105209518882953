.x1u463u47-expanded-1 {
  align-items: center;
  background-color: var(--colorwhite100);
  border: 1px none;
  display: flex;
  flex-direction: column;
  overflow: hidden;
  width: 375px;
}

.x13 .overlap-group {
  height: 493px;
  position: relative;
  width: 375px;
}

.rectangle-395 {
  height: 405px;
  left: 0;
  position: absolute;
  top: 0;
  width: 375px;
}

.header {
  align-items: flex-start;
  background-color: transparent;
  display: inline-flex;
  flex-direction: column;
  gap: 16px;
  left: 20px;
  position: absolute;
  top: 64px;
}

.res-med-logo {
  height: 42.64px;
  position: relative;
  width: 60px;
}

.title {
  color: var(--colorresmedbrandpalettegray900);
  font-family: var(--font-family-sf_pro_display-regular);
  font-size: var(--font-size-xl);
  font-weight: 400;
  letter-spacing: 0.36px;
  line-height: 34px;
  position: relative;
  text-align: start;
  width: 335px;
}

.in-this-issue-weve {
  letter-spacing: 0;
  line-height: 20px;
  position: relative;
  width: 335px;
}

.table-of-contents {
  align-items: flex-start;
  background-color: var(--colorwhite100);
  border-radius: 8px;
  box-shadow: 0px 0px 20px #0000000d;
  display: flex;
  flex-direction: column;
  gap: 12px;
  left: 20px;
  padding: 16px 24px;
  position: absolute;
  top: 293px;
  width: 335px;
}

.in-this-issue {
  color: var(--colorresmedbrandpalettegray900);
  font-family: var(--font-family-sf_pro_text-semibold);
  font-size: var(--font-size-xs);
  font-weight: 400;
  letter-spacing: -0.20px;
  line-height: 20px;
  margin-top: -1.00px;
  position: relative;
  white-space: nowrap;
  width: fit-content;
}

.table-of-contents-item {
  letter-spacing: 0;
  line-height: 20px;
  position: relative;
  white-space: nowrap;
  width: fit-content;
}

.getting-started-with-my-air {
  cursor: pointer;
  letter-spacing: 0;
  line-height: 20px;
  position: relative;
  white-space: nowrap;
  width: fit-content;
}

.tips-for-building-yo {
  letter-spacing: 0;
  line-height: 20px;
  margin-right: -1.00px;
  position: relative;
  width: 288px;
}

.frame-250 {
  align-items: flex-start;
  display: flex;
  flex-direction: column;
  gap: 16px;
  margin-top: 48px;
  position: relative;
  width: 335px;
}

.frame-230 {
  align-items: flex-start;
  display: inline-flex;
  flex: 0 0 auto;
  flex-direction: column;
  gap: 16px;
  position: relative;
}

.frame-226 {
  align-items: center;
  background-color: var(--colorwhite100);
  border-radius: 10px;
  box-shadow: 0px 0px 20px #0000000d;
  cursor: pointer;
  display: flex;
  flex: 0 0 auto;
  justify-content: space-between;
  padding: 12px 12px 12px 32px;
  position: relative;
  width: 335px;
}

.air-fit-n20 {
  letter-spacing: 0.38px;
  line-height: 25px;
  position: relative;
  white-space: nowrap;
  width: fit-content;
}

.air-fit-n20-1 {
  height: 100px;
  position: relative;
  width: 130px;
}

.what-is-cpap {
  align-items: flex-start;
  display: flex;
  flex-direction: column;
  gap: 30px;
  margin-top: 48px;
  position: relative;
  width: 335px;
}

.adapting-to-cpap {
  align-items: center;
  display: inline-flex;
  flex-direction: column;
  gap: 16px;
  margin-top: 48px;
  position: relative;
}

.air-touch-n20-outsid {
  height: 250px;
  position: relative;
  width: 375px;
}

.frame-199 {
  align-items: flex-start;
  display: flex;
  flex: 0 0 auto;
  flex-direction: column;
  gap: 16px;
  position: relative;
  width: 335px;
}

.frame-200 {
  align-items: flex-start;
  align-self: stretch;
  display: flex;
  flex: 0 0 auto;
  flex-direction: column;
  gap: 16px;
  position: relative;
  width: 100%;
}

.ing {
  align-self: stretch;
  letter-spacing: 0.38px;
  line-height: 25px;
  margin-top: -1.00px;
  position: relative;
}

.adapting-to-cpap-the,
.it-may-feel-awkward {
  align-self: stretch;
  letter-spacing: 0;
  line-height: 20px;
  position: relative;
  white-space: initial;
}

.button {
  align-items: center;
  cursor: pointer;
  display: flex;
  flex: 0 0 auto;
  gap: 4px;
  justify-content: center;
  padding: 10px 12px 10px 0px;
  position: relative;
  width: 335px;
}

.read {
  flex: 1;
  letter-spacing: 0.10px;
  line-height: 20px;
  margin-top: -1.00px;
  position: relative;
}

.chevron,
.chevron-1,
.iconsplay_arrow {
  height: 16px;
  position: relative;
  width: 16px;
}

.tip-1-practice-make {
  align-self: stretch;
  color: var(--colorresmedbrandpalettegray900);
  font-family: var(--font-family-undefined-regular);
  font-size: var(--font-size-m);
  font-weight: 400;
  letter-spacing: 0;
  line-height: 17px;
  position: relative;
}

.x13 .button-1 {
  color: var(--colorresmedbrandpalette005500);
}

.button-1-13 {
  align-items: center;
  cursor: pointer;
  display: flex;
  flex: 0 0 auto;
  gap: 4px;
  justify-content: center;
  padding: 10px 12px 10px 0px;
  position: relative;
  width: 111px;
}

.x13 .frame-219 {
  align-items: center;
  background-color: #fffffff2;
  border: 1px solid;
  border-color: var(--palettesbluesblue-300);
  border-radius: 8px;
  box-shadow: 0px 0px 20px #00000040;
  display: inline-flex;
  gap: 4px;
  left: 279px;
  padding: 6px 8px;
  position: absolute;
  top: 16px;
}

.guide {
  flex: 0 0 auto;
  position: relative;
}

.article {
  color: var(--palettesbluesblue-300);
  font-family: var(--font-family-sf_pro_text-semibold);
  font-size: var(--font-size-xxs);
  font-weight: 400;
  letter-spacing: 0;
  line-height: 16px;
  margin-top: -1.00px;
  position: relative;
  white-space: nowrap;
  width: fit-content;
}

.video {
  align-items: center;
  display: flex;
  flex-direction: column;
  gap: 24px;
  margin-top: 80px;
  position: relative;
  width: 375px;
}

.dtc_-mask-refresh202 {
  height: 251px;
  object-fit: cover;
  position: relative;
  width: 375px;
}

.frame-204 {
  align-items: flex-start;
  display: flex;
  flex: 0 0 auto;
  flex-direction: column;
  gap: 24px;
  position: relative;
  width: 335px;
}

.frame-198 {
  align-items: flex-start;
  align-self: stretch;
  display: flex;
  flex: 0 0 auto;
  flex-direction: column;
  gap: 20px;
  position: relative;
  width: 100%;
}

.x13 .frame-220 {
  align-items: center;
  background-color: var(--colorwhite100);
  border: 1px solid;
  border-color: var(--palettesdark-orangeres-dark-orange-400);
  border-radius: 8px;
  box-shadow: 0px 0px 20px #00000040;
  display: inline-flex;
  gap: 4px;
  left: 300px;
  opacity: 0.95;
  padding: 6px 8px;
  position: absolute;
  top: 16px;
}

.video-1 {
  color: var(--palettesdark-orangeres-dark-orange-400);
  font-family: var(--font-family-sf_pro_text-semibold);
  font-size: var(--font-size-xxs);
  font-weight: 400;
  letter-spacing: 0;
  line-height: 16px;
  margin-top: -1.00px;
  position: relative;
  white-space: nowrap;
  width: fit-content;
}

.frame-226-1 {
  align-items: flex-start;
  display: flex;
  flex-direction: column;
  gap: 42px;
  margin-top: 48px;
  position: relative;
  width: 335px;
}

.sign-off-rating {
  align-items: flex-start;
  align-self: stretch;
  display: flex;
  flex: 0 0 auto;
  flex-direction: column;
  gap: 52px;
  position: relative;
  width: 100%;
}

.most-people-who-use {
  align-self: stretch;
  letter-spacing: 0;
  line-height: 20px;
  margin-top: -1.00px;
  position: relative;
}

.frame-205 {
  align-items: flex-start;
  display: flex;
  flex-direction: column;
  gap: 42px;
  margin-top: 138px;
  position: relative;
  width: 335px;
}

.sign-off {
  height: 48px;
  margin-right: -135.00px;
  position: relative;
  width: 470px;
}

.sleep-well-the-my-air-team {
  color: var(--colorresmedbrandpalettegray900);
  font-family: var(--font-family-helvetica_neue-regular);
  font-size: var(--font-size-s);
  font-weight: 400;
  left: 0;
  letter-spacing: -0.16px;
  line-height: 24px;
  position: absolute;
  top: -1px;
  width: 470px;
}

.frame-225 {
  align-items: center;
  display: inline-flex;
  flex-direction: column;
  gap: 32px;
  margin-top: 48px;
  position: relative;
}

.rectangle-14 {
  background-color: var(--palettesgreysres-grey-200);
  height: 1px;
  position: relative;
  width: 335px;
}

.frame-209 {
  align-items: center;
  display: inline-flex;
  flex: 0 0 auto;
  flex-direction: column;
  gap: 16px;
  position: relative;
}

.sources {
  color: var(--black);
  font-family: var(--font-family-open_sans);
  font-size: var(--font-size-s);
  font-weight: 600;
  letter-spacing: 0;
  line-height: normal;
  margin-top: -1.00px;
  position: relative;
  width: fit-content;
}

