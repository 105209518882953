.frame-19 {
  align-items: flex-start;
  align-self: stretch;
  display: flex;
  flex: 0 0 auto;
  flex-direction: column;
  gap: 16px;
  position: relative;
  width: 100%;
}

.learn-more-about-your-mask,
.getting-started-with-my-air-1 {
  align-self: stretch;
  letter-spacing: 0.38px;
  line-height: 25px;
  margin-top: -1.00px;
  position: relative;
}

.x13 .x-your {
  align-self: stretch;
  letter-spacing: 0;
  line-height: 20px;
  position: relative;
  white-space: initial;
}

