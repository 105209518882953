.x1-2-expanded {
  align-items: center;
  background-color: var(--colorwhite100);
  border: 1px none;
  display: flex;
  flex-direction: column;
  gap: 48px;
  width: 375px;
}

.x12 .frame-199-1 {
  margin-bottom: 0px;
}

.overlap-group-2 {
  position: relative;
  width: 375px;
  padding-top: 320px;
  padding-left: 20px;
}

.rectangle-400 {
  height: 462px;
  left: 0;
  position: absolute;
  top: 0;
  width: 375px;
}

.header {
  align-items: flex-start;
  background-color: transparent;
  display: inline-flex;
  flex-direction: column;
  gap: 16px;
  left: 20px;
  top: 64px;
}

.res-med-logo {
  height: 42.64px;
  position: relative;
  width: 60px;
}

.congratulations-you {
  color: var(--texttitles);
  font-family: var(--font-family-sf_pro_display-regular);
  font-size: var(--font-size-xl);
  font-weight: 400;
  letter-spacing: 0.36px;
  line-height: 34px;
  position: relative;
  width: 335px;
}

.while-you-wait-for-y {
  letter-spacing: 0;
  line-height: 20px;
  position: relative;
  width: 335px;
}

.table-of-contents {
  align-items: flex-start;
  background-color: var(--colorwhite100);
  border-radius: 8px;
  box-shadow: 0px 0px 20px #0000000d;
  display: flex;
  flex-direction: column;
  gap: 12px;
  left: 20px;
  padding: 16px 24px;
  top: 367px;
  width: 335px;
  z-index: 100;
  position: sticky;
  margin-top: 48px;
}

.in-this-issue {
  color: var(--texttitles);
  font-family: var(--font-family-sf_pro_text-semibold);
  font-size: var(--font-size-xs);
  font-weight: 400;
  letter-spacing: -0.20px;
  line-height: 20px;
  margin-top: -1.00px;
  position: relative;
  white-space: nowrap;
  width: fit-content;
}

.x-your {
  letter-spacing: 0;
  line-height: 20px;
  position: relative;
  white-space: nowrap;
  width: fit-content;
}

.patient-story-using {
  letter-spacing: 0;
  line-height: 20px;
  margin-right: -8.00px;
  position: relative;
  white-space: nowrap;
  width: fit-content;
}

.video {
  align-items: center;
  display: flex;
  flex-direction: column;
  gap: 24px;
  padding: 0px 20px;
  position: relative;
  width: 375px;
}

.learn-more-about-your-cpap-machine,
.learn-more-about-your-mask-1,
.adapting-to-a-cpap-machine,
.patient-story-using-1 {
  align-self: stretch;
  letter-spacing: 0.38px;
  line-height: 25px;
  margin-top: -1.00px;
  position: relative;
}

.frame-204 {
  align-items: flex-start;
  display: flex;
  flex: 0 0 auto;
  flex-direction: column;
  gap: 24px;
  position: relative;
  width: 335px;
}

.frame-198 {
  align-items: flex-start;
  align-self: stretch;
  display: flex;
  flex: 0 0 auto;
  flex-direction: column;
  gap: 20px;
  position: relative;
  width: 100%;
}

.your-cpap-machine-wi {
  align-self: stretch;
  letter-spacing: 0;
  line-height: 20px;
  margin-top: -1.00px;
  position: relative;
  word-break: break-word;
  white-space: normal;
}

.learn-more-about-your-mask {
  align-items: flex-start;
  display: inline-flex;
  flex-direction: column;
  gap: 16px;
  position: relative;
}

.frame {
  align-items: flex-start;
  align-self: stretch;
  display: flex;
  flex: 0 0 auto;
  flex-direction: column;
  gap: 16px;
  position: relative;
  width: 100%;
}

.select-the-mask-you,
.starting-sleep-apnoe {
  align-self: stretch;
  letter-spacing: 0;
  line-height: 20px;
  position: relative;
}

.frame-230 {
  align-items: flex-start;
  display: inline-flex;
  flex: 0 0 auto;
  flex-direction: column;
  gap: 16px;
  position: relative;
}

.frame-226 {
  align-items: center;
  background-color: var(--colorwhite100);
  border-radius: 10px;
  box-shadow: 0px 0px 20px #0000000d;
  cursor: pointer;
  display: flex;
  flex: 0 0 auto;
  justify-content: space-between;
  padding: 12px 12px 12px 32px;
  position: relative;
  width: 335px;
}

.air-fit-n20 {
  letter-spacing: 0.38px;
  line-height: 25px;
  position: relative;
  white-space: nowrap;
  width: fit-content;
}

.air-fit-n20-1 {
  height: 100px;
  position: relative;
  width: 130px;
}

.x1-2-expanded-item {
  align-items: center;
  display: inline-flex;
  flex-direction: column;
  gap: 24px;
  position: relative;
}

.air-touch-n20-outsid {
  height: 250px;
  object-fit: cover;
  position: relative;
  width: 375px;
}

.frame-199 {
  align-items: flex-start;
  display: flex;
  flex: 0 0 auto;
  flex-direction: column;
  gap: 16px;
  position: relative;
  width: 335px;
}

.frame-200 {
  align-items: flex-start;
  align-self: stretch;
  display: flex;
  flex: 0 0 auto;
  flex-direction: column;
  gap: 16px;
  position: relative;
  width: 100%;
}

.button {
  align-items: center;
  cursor: pointer;
  display: inline-flex;
  flex: 0 0 auto;
  gap: 4px;
  justify-content: center;
  padding: 10px 12px 10px 0px;
  position: relative;
}

.read-less {
  letter-spacing: 0.10px;
  line-height: 20px;
  margin-top: -1.00px;
  position: relative;
  text-align: center;
  white-space: nowrap;
  width: fit-content;
}

.dtc_-mask-refresh2 {
  height: 142px;
  object-fit: cover;
  position: relative;
  width: 283px;
}

.chevron {
  height: 16px;
  position: relative;
  width: 16px;
}

.set-up-your-cpap-equ {
  align-self: stretch;
  color: var(--texttitles);
  font-family: var(--font-family-undefined-regular);
  font-size: var(--font-size-m);
  font-weight: 400;
  letter-spacing: 0;
  line-height: 17px;
  position: relative;
}

.frame-219-2 {
  align-items: center;
  background-color: var(--white);
  border: 1px solid;
  border-color: var(--palettesbluesblue-300);
  border-radius: 8px;
  box-shadow: 0px 0px 20px #00000040;
  display: inline-flex;
  gap: 4px;
  left: 279px;
  padding: 6px 8px;
  position: absolute;
  top: 16px;
}

.guide {
  flex: 0 0 auto;
  position: relative;
}

.article {
  color: var(--palettesbluesblue-300);
  font-family: var(--font-family-sf_pro_text-semibold);
  font-size: var(--font-size-xxs);
  font-weight: 400;
  letter-spacing: 0;
  line-height: 16px;
  margin-top: -1.00px;
  position: relative;
  white-space: nowrap;
  width: fit-content;
}

.air-touch-n20-outsid-1 {
  height: 250px;
  position: relative;
  width: 375px;
}

.frame-199-1 {
  align-items: flex-start;
  display: flex;
  flex: 0 0 auto;
  flex-direction: column;
  gap: 24px;
  position: relative;
  width: 335px;
  margin-bottom: 49px;
}

.res-meds-patient-eng {
  align-self: stretch;
  color: transparent;
  font-family: var(--font-family-sf_pro_text-regular);
  font-size: var(--font-size-xs);
  font-weight: 400;
  letter-spacing: 0;
  line-height: 20px;
  position: relative;
}

.frame-219-1 {
  align-items: center;
  background-color: var(--white);
  border: 1px solid;
  border-color: var(--palettespurpleres-purple-500);
  border-radius: 8px;
  box-shadow: 0px 0px 20px #00000040;
  display: inline-flex;
  gap: 4px;
  left: 243px;
  padding: 6px 8px;
  position: absolute;
  top: 16px;
}

.patient-story {
  color: var(--palettespurpleres-purple-500);
  font-family: var(--font-family-sf_pro_text-semibold);
  font-size: var(--font-size-xxs);
  font-weight: 400;
  letter-spacing: 0;
  line-height: 16px;
  margin-top: -1.00px;
  position: relative;
  white-space: nowrap;
  width: fit-content;
}

.x12 .align-items-start {
  align-items: start;
  display: flex;
  flex-direction: column;
  gap: 24px;
}

.break-word-flex {
  word-break: break-word;
  white-space: break-spaces;
}

.dtc_-mask-refresh3 {
  width: 205px;
}