.x1-2-mask-selected-n20 {
  align-items: center;
  background-color: var(--colorwhite100);
  border: 1px none;
  display: flex;
  flex-direction: column;
  padding: 16px 12px;
  position: relative;
  width: 375px;
}

.chevron.over.ride {
  height: 24px;
  position: relative;
  width: 26px;
}

.vector-4 {
  height: 18px;
  left: 7px;
  position: absolute;
  top: 3px;
  width: 10px;
}

.navbarback {
  align-items: center;
  align-self: flex-start;
  display: inline-flex;
  gap: 1px;
  position: relative;
}

.x12-selected .title {
  color: var(--colorresmedbrandpalettegray900);
  font-family: var(--font-family-sf_pro_display-regular);
  font-size: var(--font-size-xxxxl);
  font-weight: 400;
  letter-spacing: 0.36px;
  line-height: 34px;
  margin-top: 7px;
  text-align: center;
  width: 335px;
}

.x12-selected .air-fit-n20 {
  height: 158px;
  margin-top: 24px;
  width: 205px;
}

.the-air-fit-n20-mask {
  letter-spacing: 0;
  line-height: 20px;
  margin-top: 24px;
  min-height: 140px;
  width: 335px;
}

.mask-fit {
  align-self: flex-start;
  letter-spacing: 0.38px;
  line-height: 25px;
  margin-left: 8px;
  margin-top: 48px;
  white-space: nowrap;
}

.cleaning {
  align-self: flex-start;
  letter-spacing: 0.38px;
  line-height: 25px;
  margin-top: 48px;
  white-space: nowrap;
}

.frame-247 {
  align-items: flex-start;
  display: inline-flex;
  flex-direction: column;
  gap: 16px;
  margin-top: 48px;
  position: relative;
}

.fa-qs {
  letter-spacing: 0.38px;
  line-height: 25px;
  margin-top: -1.00px;
  position: relative;
  white-space: nowrap;
  width: fit-content;
}

.if-you-have-further {
  color: transparent;
  font-family: var(--font-family-sf_pro_text-regular);
  font-size: var(--font-size-xs);
  font-weight: 400;
  letter-spacing: 0;
  line-height: 20px;
  margin-top: 47px;
  min-height: 40px;
  width: 335px;
}

.span1 {
  color: var(--colorresmedbrandpalette005500);
  text-decoration: underline;
}

.button {
  align-items: center;
  cursor: pointer;
  display: inline-flex;
  gap: 4px;
  justify-content: center;
  margin-left: 1.0px;
  margin-top: 49px;
  padding: 10px 12px;
  position: relative;
}

.place {
  color: var(--colorresmedbrandpalette005500);
  font-family: var(--font-family-sf_pro_text-semibold);
  font-size: var(--font-size-s);
  font-weight: 400;
  letter-spacing: 0.10px;
  line-height: 20px;
  margin-top: -1.00px;
  position: relative;
  text-align: center;
  white-space: nowrap;
  width: fit-content;
}

.x12-selected .gradient-card-0, .x12-selected .gradient-card-2 {
  margin-top: 24px;
}
.the-air-fit-n20-mask.sfprotext-regular-normal-log-cabin-14px b {
  color: var(--text-titles, #212121);
  /* Body/Small Bold */
  font-family: var(--font-family-sf_pro_text-semibold);
  font-size: 14px;
  font-style: normal;
  font-weight: 600;
  line-height: 20px;
}