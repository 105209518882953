@import "https://cdnjs.cloudflare.com/ajax/libs/meyer-reset/2.0/reset.min.css";
@font-face {
  font-family: SF Pro Display-Regular;
  font-style: normal;
  font-weight: 400;
  src: url("SF-Pro-Display-Regular.6b71954b.otf") format("opentype");
}

@font-face {
  font-family: SF Pro Text-Regular;
  font-style: normal;
  font-weight: 400;
  src: url("FontsFree-Net-SFProText-Regular.82a5efa9.ttf") format("truetype");
}

@font-face {
  font-family: SF Pro Text-Semibold;
  font-style: normal;
  font-weight: 400;
  src: url("FontsFree-Net-SFProText-Semibold.8725b6f5.ttf") format("truetype");
}

@font-face {
  font-family: SF Pro Display-Semibold;
  font-style: normal;
  font-weight: 400;
  src: url("FontsFree-Net-SFProDisplay-Semibold.3431555e.ttf") format("truetype");
}

@font-face {
  font-family: undefined-Regular;
  font-style: normal;
  font-weight: 400;
  src: url("Augusta.7fe27ba9.ttf") format("truetype");
}

.screen a {
  text-decoration: none;
  display: contents;
}

.container-center-horizontal {
  pointer-events: none;
  flex-direction: row;
  justify-content: center;
  width: 100%;
  display: flex;
}

.container-center-horizontal > * {
  pointer-events: auto;
  flex-shrink: 0;
}

* {
  box-sizing: border-box;
}

.see-more-title {
  color: var(--text-titles, #212121);
  font-family: var(--font-family-sf_pro_text-semibold);
  font-size: 17px;
  font-style: normal;
  font-weight: 600;
  line-height: 25.5px;
}

.see-more-text {
  color: var(--text-titles, #212121);
  font-family: var(--font-family-sf_pro_text-regular);
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 20px;
}

.see-more-note {
  color: var(--text-titles, #212121);
  font-family: var(--font-family-sf_pro_text-regular);
  font-size: 12px;
  font-style: italic;
  font-weight: 400;
  line-height: 16px;
}

.see-more-text-bold {
  color: var(--text-titles, #212121);
  font-family: var(--font-family-sf_pro_text-semibold);
  font-size: 14px;
  font-style: normal;
  font-weight: 600;
  line-height: 20px;
}

.x1u463u47-expanded-1 {
  background-color: var(--colorwhite100);
  border: 1px;
  flex-direction: column;
  align-items: center;
  width: 375px;
  display: flex;
  overflow: hidden;
}

.x13 .overlap-group {
  width: 375px;
  height: 493px;
  position: relative;
}

.rectangle-395 {
  width: 375px;
  height: 405px;
  position: absolute;
  top: 0;
  left: 0;
}

.header {
  background-color: #0000;
  flex-direction: column;
  align-items: flex-start;
  gap: 16px;
  display: inline-flex;
  position: absolute;
  top: 64px;
  left: 20px;
}

.res-med-logo {
  width: 60px;
  height: 42.64px;
  position: relative;
}

.title {
  color: var(--colorresmedbrandpalettegray900);
  font-family: var(--font-family-sf_pro_display-regular);
  font-size: var(--font-size-xl);
  letter-spacing: .36px;
  text-align: start;
  width: 335px;
  font-weight: 400;
  line-height: 34px;
  position: relative;
}

.in-this-issue-weve {
  letter-spacing: 0;
  width: 335px;
  line-height: 20px;
  position: relative;
}

.table-of-contents {
  background-color: var(--colorwhite100);
  border-radius: 8px;
  flex-direction: column;
  align-items: flex-start;
  gap: 12px;
  width: 335px;
  padding: 16px 24px;
  display: flex;
  position: absolute;
  top: 293px;
  left: 20px;
  box-shadow: 0 0 20px #0000000d;
}

.in-this-issue {
  color: var(--colorresmedbrandpalettegray900);
  font-family: var(--font-family-sf_pro_text-semibold);
  font-size: var(--font-size-xs);
  letter-spacing: -.2px;
  white-space: nowrap;
  width: -moz-fit-content;
  width: fit-content;
  margin-top: -1px;
  font-weight: 400;
  line-height: 20px;
  position: relative;
}

.table-of-contents-item {
  letter-spacing: 0;
  white-space: nowrap;
  width: -moz-fit-content;
  width: fit-content;
  line-height: 20px;
  position: relative;
}

.getting-started-with-my-air {
  cursor: pointer;
  letter-spacing: 0;
  white-space: nowrap;
  width: -moz-fit-content;
  width: fit-content;
  line-height: 20px;
  position: relative;
}

.tips-for-building-yo {
  letter-spacing: 0;
  width: 288px;
  margin-right: -1px;
  line-height: 20px;
  position: relative;
}

.frame-250 {
  flex-direction: column;
  align-items: flex-start;
  gap: 16px;
  width: 335px;
  margin-top: 48px;
  display: flex;
  position: relative;
}

.frame-230 {
  flex-direction: column;
  flex: none;
  align-items: flex-start;
  gap: 16px;
  display: inline-flex;
  position: relative;
}

.frame-226 {
  background-color: var(--colorwhite100);
  cursor: pointer;
  border-radius: 10px;
  flex: none;
  justify-content: space-between;
  align-items: center;
  width: 335px;
  padding: 12px 12px 12px 32px;
  display: flex;
  position: relative;
  box-shadow: 0 0 20px #0000000d;
}

.air-fit-n20 {
  letter-spacing: .38px;
  white-space: nowrap;
  width: -moz-fit-content;
  width: fit-content;
  line-height: 25px;
  position: relative;
}

.air-fit-n20-1 {
  width: 130px;
  height: 100px;
  position: relative;
}

.what-is-cpap {
  flex-direction: column;
  align-items: flex-start;
  gap: 30px;
  width: 335px;
  margin-top: 48px;
  display: flex;
  position: relative;
}

.adapting-to-cpap {
  flex-direction: column;
  align-items: center;
  gap: 16px;
  margin-top: 48px;
  display: inline-flex;
  position: relative;
}

.air-touch-n20-outsid {
  width: 375px;
  height: 250px;
  position: relative;
}

.frame-199 {
  flex-direction: column;
  flex: none;
  align-items: flex-start;
  gap: 16px;
  width: 335px;
  display: flex;
  position: relative;
}

.frame-200 {
  flex-direction: column;
  flex: none;
  align-self: stretch;
  align-items: flex-start;
  gap: 16px;
  width: 100%;
  display: flex;
  position: relative;
}

.ing {
  letter-spacing: .38px;
  align-self: stretch;
  margin-top: -1px;
  line-height: 25px;
  position: relative;
}

.adapting-to-cpap-the, .it-may-feel-awkward {
  letter-spacing: 0;
  white-space: initial;
  align-self: stretch;
  line-height: 20px;
  position: relative;
}

.button {
  cursor: pointer;
  flex: none;
  justify-content: center;
  align-items: center;
  gap: 4px;
  width: 335px;
  padding: 10px 12px 10px 0;
  display: flex;
  position: relative;
}

.read {
  letter-spacing: .1px;
  flex: 1;
  margin-top: -1px;
  line-height: 20px;
  position: relative;
}

.chevron, .chevron-1, .iconsplay_arrow {
  width: 16px;
  height: 16px;
  position: relative;
}

.tip-1-practice-make {
  color: var(--colorresmedbrandpalettegray900);
  font-family: var(--font-family-undefined-regular);
  font-size: var(--font-size-m);
  letter-spacing: 0;
  align-self: stretch;
  font-weight: 400;
  line-height: 17px;
  position: relative;
}

.x13 .button-1 {
  color: var(--colorresmedbrandpalette005500);
}

.button-1-13 {
  cursor: pointer;
  flex: none;
  justify-content: center;
  align-items: center;
  gap: 4px;
  width: 111px;
  padding: 10px 12px 10px 0;
  display: flex;
  position: relative;
}

.x13 .frame-219 {
  border: 1px solid;
  border-color: var(--palettesbluesblue-300);
  background-color: #fffffff2;
  border-radius: 8px;
  align-items: center;
  gap: 4px;
  padding: 6px 8px;
  display: inline-flex;
  position: absolute;
  top: 16px;
  left: 279px;
  box-shadow: 0 0 20px #00000040;
}

.guide {
  flex: none;
  position: relative;
}

.article {
  color: var(--palettesbluesblue-300);
  font-family: var(--font-family-sf_pro_text-semibold);
  font-size: var(--font-size-xxs);
  letter-spacing: 0;
  white-space: nowrap;
  width: -moz-fit-content;
  width: fit-content;
  margin-top: -1px;
  font-weight: 400;
  line-height: 16px;
  position: relative;
}

.video {
  flex-direction: column;
  align-items: center;
  gap: 24px;
  width: 375px;
  margin-top: 80px;
  display: flex;
  position: relative;
}

.dtc_-mask-refresh202 {
  object-fit: cover;
  width: 375px;
  height: 251px;
  position: relative;
}

.frame-204 {
  flex-direction: column;
  flex: none;
  align-items: flex-start;
  gap: 24px;
  width: 335px;
  display: flex;
  position: relative;
}

.frame-198 {
  flex-direction: column;
  flex: none;
  align-self: stretch;
  align-items: flex-start;
  gap: 20px;
  width: 100%;
  display: flex;
  position: relative;
}

.x13 .frame-220 {
  background-color: var(--colorwhite100);
  border: 1px solid;
  border-color: var(--palettesdark-orangeres-dark-orange-400);
  opacity: .95;
  border-radius: 8px;
  align-items: center;
  gap: 4px;
  padding: 6px 8px;
  display: inline-flex;
  position: absolute;
  top: 16px;
  left: 300px;
  box-shadow: 0 0 20px #00000040;
}

.video-1 {
  color: var(--palettesdark-orangeres-dark-orange-400);
  font-family: var(--font-family-sf_pro_text-semibold);
  font-size: var(--font-size-xxs);
  letter-spacing: 0;
  white-space: nowrap;
  width: -moz-fit-content;
  width: fit-content;
  margin-top: -1px;
  font-weight: 400;
  line-height: 16px;
  position: relative;
}

.frame-226-1 {
  flex-direction: column;
  align-items: flex-start;
  gap: 42px;
  width: 335px;
  margin-top: 48px;
  display: flex;
  position: relative;
}

.sign-off-rating {
  flex-direction: column;
  flex: none;
  align-self: stretch;
  align-items: flex-start;
  gap: 52px;
  width: 100%;
  display: flex;
  position: relative;
}

.most-people-who-use {
  letter-spacing: 0;
  align-self: stretch;
  margin-top: -1px;
  line-height: 20px;
  position: relative;
}

.frame-205 {
  flex-direction: column;
  align-items: flex-start;
  gap: 42px;
  width: 335px;
  margin-top: 138px;
  display: flex;
  position: relative;
}

.sign-off {
  width: 470px;
  height: 48px;
  margin-right: -135px;
  position: relative;
}

.sleep-well-the-my-air-team {
  color: var(--colorresmedbrandpalettegray900);
  font-family: var(--font-family-helvetica_neue-regular);
  font-size: var(--font-size-s);
  letter-spacing: -.16px;
  width: 470px;
  font-weight: 400;
  line-height: 24px;
  position: absolute;
  top: -1px;
  left: 0;
}

.frame-225 {
  flex-direction: column;
  align-items: center;
  gap: 32px;
  margin-top: 48px;
  display: inline-flex;
  position: relative;
}

.rectangle-14 {
  background-color: var(--palettesgreysres-grey-200);
  width: 335px;
  height: 1px;
  position: relative;
}

.frame-209 {
  flex-direction: column;
  flex: none;
  align-items: center;
  gap: 16px;
  display: inline-flex;
  position: relative;
}

.sources {
  color: var(--black);
  font-family: var(--font-family-open_sans);
  font-size: var(--font-size-s);
  letter-spacing: 0;
  width: -moz-fit-content;
  width: fit-content;
  margin-top: -1px;
  font-weight: 600;
  line-height: normal;
  position: relative;
}

.x1-2-mask-selected-n20 {
  background-color: var(--colorwhite100);
  border: 1px;
  flex-direction: column;
  align-items: center;
  width: 375px;
  padding: 16px 12px;
  display: flex;
  position: relative;
}

.chevron.over.ride {
  width: 26px;
  height: 24px;
  position: relative;
}

.vector-4 {
  width: 10px;
  height: 18px;
  position: absolute;
  top: 3px;
  left: 7px;
}

.navbarback {
  align-self: flex-start;
  align-items: center;
  gap: 1px;
  display: inline-flex;
  position: relative;
}

.x12-selected .title {
  color: var(--colorresmedbrandpalettegray900);
  font-family: var(--font-family-sf_pro_display-regular);
  font-size: var(--font-size-xxxxl);
  letter-spacing: .36px;
  text-align: center;
  width: 335px;
  margin-top: 7px;
  font-weight: 400;
  line-height: 34px;
}

.x12-selected .air-fit-n20 {
  width: 205px;
  height: 158px;
  margin-top: 24px;
}

.the-air-fit-n20-mask {
  letter-spacing: 0;
  width: 335px;
  min-height: 140px;
  margin-top: 24px;
  line-height: 20px;
}

.mask-fit {
  letter-spacing: .38px;
  white-space: nowrap;
  align-self: flex-start;
  margin-top: 48px;
  margin-left: 8px;
  line-height: 25px;
}

.cleaning {
  letter-spacing: .38px;
  white-space: nowrap;
  align-self: flex-start;
  margin-top: 48px;
  line-height: 25px;
}

.frame-247 {
  flex-direction: column;
  align-items: flex-start;
  gap: 16px;
  margin-top: 48px;
  display: inline-flex;
  position: relative;
}

.fa-qs {
  letter-spacing: .38px;
  white-space: nowrap;
  width: -moz-fit-content;
  width: fit-content;
  margin-top: -1px;
  line-height: 25px;
  position: relative;
}

.if-you-have-further {
  color: #0000;
  font-family: var(--font-family-sf_pro_text-regular);
  font-size: var(--font-size-xs);
  letter-spacing: 0;
  width: 335px;
  min-height: 40px;
  margin-top: 47px;
  font-weight: 400;
  line-height: 20px;
}

.span1 {
  color: var(--colorresmedbrandpalette005500);
  text-decoration: underline;
}

.button {
  cursor: pointer;
  justify-content: center;
  align-items: center;
  gap: 4px;
  margin-top: 49px;
  margin-left: 1px;
  padding: 10px 12px;
  display: inline-flex;
  position: relative;
}

.place {
  color: var(--colorresmedbrandpalette005500);
  font-family: var(--font-family-sf_pro_text-semibold);
  font-size: var(--font-size-s);
  letter-spacing: .1px;
  text-align: center;
  white-space: nowrap;
  width: -moz-fit-content;
  width: fit-content;
  margin-top: -1px;
  font-weight: 400;
  line-height: 20px;
  position: relative;
}

.x12-selected .gradient-card-0, .x12-selected .gradient-card-2 {
  margin-top: 24px;
}

.the-air-fit-n20-mask.sfprotext-regular-normal-log-cabin-14px b {
  color: var(--text-titles, #212121);
  font-family: var(--font-family-sf_pro_text-semibold);
  font-size: 14px;
  font-style: normal;
  font-weight: 600;
  line-height: 20px;
}

.x1-2-expanded {
  background-color: var(--colorwhite100);
  border: 1px;
  flex-direction: column;
  align-items: center;
  gap: 48px;
  width: 375px;
  display: flex;
}

.x12 .frame-199-1 {
  margin-bottom: 0;
}

.overlap-group-2 {
  width: 375px;
  padding-top: 320px;
  padding-left: 20px;
  position: relative;
}

.rectangle-400 {
  width: 375px;
  height: 462px;
  position: absolute;
  top: 0;
  left: 0;
}

.header {
  background-color: #0000;
  flex-direction: column;
  align-items: flex-start;
  gap: 16px;
  display: inline-flex;
  top: 64px;
  left: 20px;
}

.res-med-logo {
  width: 60px;
  height: 42.64px;
  position: relative;
}

.congratulations-you {
  color: var(--texttitles);
  font-family: var(--font-family-sf_pro_display-regular);
  font-size: var(--font-size-xl);
  letter-spacing: .36px;
  width: 335px;
  font-weight: 400;
  line-height: 34px;
  position: relative;
}

.while-you-wait-for-y {
  letter-spacing: 0;
  width: 335px;
  line-height: 20px;
  position: relative;
}

.table-of-contents {
  background-color: var(--colorwhite100);
  z-index: 100;
  border-radius: 8px;
  flex-direction: column;
  align-items: flex-start;
  gap: 12px;
  width: 335px;
  margin-top: 48px;
  padding: 16px 24px;
  display: flex;
  position: sticky;
  top: 367px;
  left: 20px;
  box-shadow: 0 0 20px #0000000d;
}

.in-this-issue {
  color: var(--texttitles);
  font-family: var(--font-family-sf_pro_text-semibold);
  font-size: var(--font-size-xs);
  letter-spacing: -.2px;
  white-space: nowrap;
  width: -moz-fit-content;
  width: fit-content;
  margin-top: -1px;
  font-weight: 400;
  line-height: 20px;
  position: relative;
}

.x-your {
  letter-spacing: 0;
  white-space: nowrap;
  width: -moz-fit-content;
  width: fit-content;
  line-height: 20px;
  position: relative;
}

.patient-story-using {
  letter-spacing: 0;
  white-space: nowrap;
  width: -moz-fit-content;
  width: fit-content;
  margin-right: -8px;
  line-height: 20px;
  position: relative;
}

.video {
  flex-direction: column;
  align-items: center;
  gap: 24px;
  width: 375px;
  padding: 0 20px;
  display: flex;
  position: relative;
}

.learn-more-about-your-cpap-machine, .learn-more-about-your-mask-1, .adapting-to-a-cpap-machine, .patient-story-using-1 {
  letter-spacing: .38px;
  align-self: stretch;
  margin-top: -1px;
  line-height: 25px;
  position: relative;
}

.frame-204 {
  flex-direction: column;
  flex: none;
  align-items: flex-start;
  gap: 24px;
  width: 335px;
  display: flex;
  position: relative;
}

.frame-198 {
  flex-direction: column;
  flex: none;
  align-self: stretch;
  align-items: flex-start;
  gap: 20px;
  width: 100%;
  display: flex;
  position: relative;
}

.your-cpap-machine-wi {
  letter-spacing: 0;
  word-break: break-word;
  white-space: normal;
  align-self: stretch;
  margin-top: -1px;
  line-height: 20px;
  position: relative;
}

.learn-more-about-your-mask {
  flex-direction: column;
  align-items: flex-start;
  gap: 16px;
  display: inline-flex;
  position: relative;
}

.frame {
  flex-direction: column;
  flex: none;
  align-self: stretch;
  align-items: flex-start;
  gap: 16px;
  width: 100%;
  display: flex;
  position: relative;
}

.select-the-mask-you, .starting-sleep-apnoe {
  letter-spacing: 0;
  align-self: stretch;
  line-height: 20px;
  position: relative;
}

.frame-230 {
  flex-direction: column;
  flex: none;
  align-items: flex-start;
  gap: 16px;
  display: inline-flex;
  position: relative;
}

.frame-226 {
  background-color: var(--colorwhite100);
  cursor: pointer;
  border-radius: 10px;
  flex: none;
  justify-content: space-between;
  align-items: center;
  width: 335px;
  padding: 12px 12px 12px 32px;
  display: flex;
  position: relative;
  box-shadow: 0 0 20px #0000000d;
}

.air-fit-n20 {
  letter-spacing: .38px;
  white-space: nowrap;
  width: -moz-fit-content;
  width: fit-content;
  line-height: 25px;
  position: relative;
}

.air-fit-n20-1 {
  width: 130px;
  height: 100px;
  position: relative;
}

.x1-2-expanded-item {
  flex-direction: column;
  align-items: center;
  gap: 24px;
  display: inline-flex;
  position: relative;
}

.air-touch-n20-outsid {
  object-fit: cover;
  width: 375px;
  height: 250px;
  position: relative;
}

.frame-199 {
  flex-direction: column;
  flex: none;
  align-items: flex-start;
  gap: 16px;
  width: 335px;
  display: flex;
  position: relative;
}

.frame-200 {
  flex-direction: column;
  flex: none;
  align-self: stretch;
  align-items: flex-start;
  gap: 16px;
  width: 100%;
  display: flex;
  position: relative;
}

.button {
  cursor: pointer;
  flex: none;
  justify-content: center;
  align-items: center;
  gap: 4px;
  padding: 10px 12px 10px 0;
  display: inline-flex;
  position: relative;
}

.read-less {
  letter-spacing: .1px;
  text-align: center;
  white-space: nowrap;
  width: -moz-fit-content;
  width: fit-content;
  margin-top: -1px;
  line-height: 20px;
  position: relative;
}

.dtc_-mask-refresh2 {
  object-fit: cover;
  width: 283px;
  height: 142px;
  position: relative;
}

.chevron {
  width: 16px;
  height: 16px;
  position: relative;
}

.set-up-your-cpap-equ {
  color: var(--texttitles);
  font-family: var(--font-family-undefined-regular);
  font-size: var(--font-size-m);
  letter-spacing: 0;
  align-self: stretch;
  font-weight: 400;
  line-height: 17px;
  position: relative;
}

.frame-219-2 {
  background-color: var(--white);
  border: 1px solid;
  border-color: var(--palettesbluesblue-300);
  border-radius: 8px;
  align-items: center;
  gap: 4px;
  padding: 6px 8px;
  display: inline-flex;
  position: absolute;
  top: 16px;
  left: 279px;
  box-shadow: 0 0 20px #00000040;
}

.guide {
  flex: none;
  position: relative;
}

.article {
  color: var(--palettesbluesblue-300);
  font-family: var(--font-family-sf_pro_text-semibold);
  font-size: var(--font-size-xxs);
  letter-spacing: 0;
  white-space: nowrap;
  width: -moz-fit-content;
  width: fit-content;
  margin-top: -1px;
  font-weight: 400;
  line-height: 16px;
  position: relative;
}

.air-touch-n20-outsid-1 {
  width: 375px;
  height: 250px;
  position: relative;
}

.frame-199-1 {
  flex-direction: column;
  flex: none;
  align-items: flex-start;
  gap: 24px;
  width: 335px;
  margin-bottom: 49px;
  display: flex;
  position: relative;
}

.res-meds-patient-eng {
  color: #0000;
  font-family: var(--font-family-sf_pro_text-regular);
  font-size: var(--font-size-xs);
  letter-spacing: 0;
  align-self: stretch;
  font-weight: 400;
  line-height: 20px;
  position: relative;
}

.frame-219-1 {
  background-color: var(--white);
  border: 1px solid;
  border-color: var(--palettespurpleres-purple-500);
  border-radius: 8px;
  align-items: center;
  gap: 4px;
  padding: 6px 8px;
  display: inline-flex;
  position: absolute;
  top: 16px;
  left: 243px;
  box-shadow: 0 0 20px #00000040;
}

.patient-story {
  color: var(--palettespurpleres-purple-500);
  font-family: var(--font-family-sf_pro_text-semibold);
  font-size: var(--font-size-xxs);
  letter-spacing: 0;
  white-space: nowrap;
  width: -moz-fit-content;
  width: fit-content;
  margin-top: -1px;
  font-weight: 400;
  line-height: 16px;
  position: relative;
}

.x12 .align-items-start {
  flex-direction: column;
  align-items: start;
  gap: 24px;
  display: flex;
}

.break-word-flex {
  word-break: break-word;
  white-space: break-spaces;
}

.dtc_-mask-refresh3 {
  width: 205px;
}

.button-1 {
  background-color: var(--colorresmedbrandpalette005500);
  color: var(--colorresmedbrandpalette005500);
  border: 1px solid;
  border-radius: 30px;
  flex: none;
  justify-content: center;
  align-items: center;
  gap: 8px;
  padding: 10px 24px;
  display: inline-flex;
  position: relative;
}

.start-stop {
  width: 16px;
  height: 16px;
  position: relative;
}

.button-2 {
  color: var(--colorwhite100);
  font-family: var(--font-family-sf_pro_text-semibold);
  font-size: var(--font-size-s);
  letter-spacing: .1px;
  text-align: center;
  white-space: nowrap;
  width: -moz-fit-content;
  width: fit-content;
  margin-top: -1px;
  font-weight: 400;
  line-height: 20px;
  position: relative;
}

.youtube-iframe {
  z-index: 10;
  position: absolute;
  left: -20px;
}

.x1-1-expanded {
  background-color: var(--colorwhite100);
  border: 1px;
  flex-direction: column;
  align-items: center;
  width: 375px;
  display: flex;
}

.overlap-group {
  width: 375px;
  position: relative;
}

.rectangle-395 {
  width: 375px;
  height: 407px;
  position: absolute;
  top: 0;
  left: 0;
}

.frame-224 {
  flex-direction: column;
  align-items: center;
  gap: 48px;
  width: 375px;
  margin-top: 64px;
  display: flex;
  top: 64px;
  left: 0;
}

.frame-211 {
  flex-direction: column;
  flex: none;
  align-items: flex-start;
  gap: 16px;
  display: inline-flex;
  position: relative;
}

.res-med-logo {
  width: 60px;
  height: 42.64px;
  position: relative;
}

.title {
  color: var(--texttitles);
  font-family: var(--font-family-sf_pro_display-regular);
  font-size: var(--font-size-xl);
  letter-spacing: .36px;
  text-align: start;
  width: 335px;
  font-weight: 400;
  line-height: 34px;
  position: relative;
}

.well-be-sending-you {
  letter-spacing: 0;
  width: 335px;
  line-height: 20px;
  position: relative;
}

.frame-212 {
  background-color: var(--colorwhite100);
  border-radius: 8px;
  flex-direction: column;
  flex: none;
  align-items: flex-start;
  gap: 12px;
  width: 335px;
  padding: 16px 24px;
  display: flex;
  position: relative;
  box-shadow: 0 0 20px #0000000d;
}

.in-this-issue {
  color: var(--texttitles);
  font-family: var(--font-family-sf_pro_text-semibold);
  font-size: var(--font-size-xs);
  letter-spacing: -.2px;
  white-space: nowrap;
  width: -moz-fit-content;
  width: fit-content;
  margin-top: -1px;
  font-weight: 400;
  line-height: 20px;
  position: relative;
}

.frame-212-item {
  letter-spacing: 0;
  white-space: nowrap;
  width: -moz-fit-content;
  width: fit-content;
  line-height: 20px;
  position: relative;
}

.frame-203 {
  flex-direction: column;
  flex: none;
  align-items: flex-start;
  gap: 30px;
  width: 335px;
  display: flex;
  position: relative;
}

.frame {
  flex-direction: column;
  flex: none;
  align-self: stretch;
  align-items: flex-start;
  gap: 16px;
  width: 100%;
  display: flex;
  position: relative;
}

.what {
  letter-spacing: .38px;
  white-space: nowrap;
  align-self: stretch;
  margin-top: -1px;
  line-height: 25px;
  position: relative;
}

.new-patients-often-s, .it-took-some-time-fo, .name {
  letter-spacing: 0;
  align-self: stretch;
  line-height: 20px;
  position: relative;
}

.frame-222 {
  flex-direction: column;
  flex: none;
  align-items: center;
  gap: 24px;
  width: 375px;
  display: flex;
  position: relative;
}

.dtc_-mask-refresh202 {
  object-fit: cover;
  width: 375px;
  height: 251px;
  position: relative;
}

.frame-1 {
  flex-direction: column;
  flex: none;
  align-items: flex-start;
  gap: 24px;
  width: 335px;
  display: flex;
  position: relative;
}

.frame-220 {
  background-color: var(--colorwhite100);
  border: 1px solid;
  border-color: var(--palettesdark-orangeres-dark-orange-400);
  opacity: .95;
  border-radius: 8px;
  align-items: center;
  gap: 4px;
  padding: 6px 8px;
  display: inline-flex;
  position: absolute;
  top: 16px;
  left: 286px;
  box-shadow: 0 0 20px #00000040;
}

.iconsplay_arrow, .chevron, .chevron-1 {
  width: 16px;
  height: 16px;
  position: relative;
}

.video {
  color: var(--palettesdark-orangeres-dark-orange-400);
  font-family: var(--font-family-sf_pro_text-semibold);
  font-size: var(--font-size-xxs);
  letter-spacing: 0;
  white-space: nowrap;
  width: -moz-fit-content;
  width: fit-content;
  margin-top: -1px;
  font-weight: 400;
  line-height: 16px;
  position: relative;
}

.frame-223 {
  flex-direction: column;
  flex: none;
  align-items: center;
  gap: 16px;
  display: inline-flex;
  position: relative;
}

.air-touch-n20-outsid {
  object-fit: cover;
  width: 375px;
  height: 250px;
  position: relative;
}

.patient-story-from {
  letter-spacing: .38px;
  align-self: stretch;
  margin-top: -1px;
  line-height: 25px;
  position: relative;
}

.button {
  cursor: pointer;
  flex: none;
  justify-content: center;
  align-items: center;
  gap: 4px;
  padding: 10px 12px 10px 0;
  display: inline-flex;
  position: relative;
}

.text-button {
  letter-spacing: .1px;
  text-align: center;
  white-space: nowrap;
  width: -moz-fit-content;
  width: fit-content;
  margin-top: -1px;
  line-height: 20px;
  position: relative;
}

.tell-us-about-your-s {
  color: var(--texttitles);
  font-family: var(--font-family-undefined-regular);
  font-size: var(--font-size-m);
  letter-spacing: 0;
  align-self: stretch;
  font-weight: 400;
  line-height: 17px;
  position: relative;
}

.frame-219 {
  border: 1px solid;
  border-color: var(--palettespurpleres-purple-500);
  background-color: #fffffff2;
  border-radius: 8px;
  align-items: center;
  gap: 4px;
  padding: 6px 8px;
  display: inline-flex;
  position: absolute;
  top: 16px;
  left: 243px;
  box-shadow: 0 0 20px #00000040;
}

.patient-story {
  color: var(--palettespurpleres-purple-500);
  font-family: var(--font-family-sf_pro_text-semibold);
  font-size: var(--font-size-xxs);
  letter-spacing: 0;
  white-space: nowrap;
  width: -moz-fit-content;
  width: fit-content;
  margin-top: -1px;
  font-weight: 400;
  line-height: 16px;
  position: relative;
}

.frame-249 {
  flex-direction: column;
  align-items: flex-start;
  gap: 16px;
  margin-top: 48px;
  display: inline-flex;
  position: relative;
}

.air-connect-fa-qs {
  letter-spacing: .38px;
  white-space: nowrap;
  width: -moz-fit-content;
  width: fit-content;
  margin-top: -1px;
  line-height: 25px;
  position: relative;
}

.overlap-group1 {
  width: 375px;
  height: 405px;
  margin-top: 36px;
  position: relative;
}

.sign-off {
  width: 335px;
  height: 48px;
  position: absolute;
  top: 0;
  left: 20px;
}

.frame-225 {
  background-color: #efefef;
  flex-direction: column;
  align-items: center;
  gap: 32px;
  width: 375px;
  padding: 0 20px 32px;
  display: flex;
  position: absolute;
  top: 12px;
  left: 0;
}

.rectangle-14 {
  background-color: var(--palettesgreysres-grey-200);
  width: 335px;
  height: 1px;
  position: relative;
}

.frame-209 {
  flex-direction: column;
  flex: none;
  align-self: stretch;
  align-items: center;
  gap: 16px;
  width: 100%;
  display: flex;
  position: relative;
}

.sources {
  color: var(--black);
  font-family: var(--font-family-sf_pro_text-regular);
  font-size: var(--font-size-m);
  letter-spacing: 0;
  white-space: nowrap;
  align-self: stretch;
  margin-top: -1px;
  font-weight: 400;
  line-height: 25.5px;
  position: relative;
}

.lal-c-strange-c-ba {
  letter-spacing: 0;
  align-self: stretch;
  line-height: 16px;
  position: relative;
}

.span5 {
  text-decoration: underline;
}

ul {
  list-style: auto;
}

.x1 .video {
  margin-top: 1px;
  padding: 0;
}

.x1u463u47-expanded-1 {
  background-color: var(--colorwhite100);
  border: 1px;
  flex-direction: column;
  align-items: center;
  width: 375px;
  display: flex;
  overflow: hidden;
}

.x13 .overlap-group {
  width: 375px;
  height: 493px;
  position: relative;
}

.rectangle-395 {
  width: 375px;
  height: 405px;
  position: absolute;
  top: 0;
  left: 0;
}

.header {
  background-color: #0000;
  flex-direction: column;
  align-items: flex-start;
  gap: 16px;
  display: inline-flex;
  position: absolute;
  top: 64px;
  left: 20px;
}

.res-med-logo {
  width: 60px;
  height: 42.64px;
  position: relative;
}

.title {
  color: var(--colorresmedbrandpalettegray900);
  font-family: var(--font-family-sf_pro_display-regular);
  font-size: var(--font-size-xl);
  letter-spacing: .36px;
  text-align: start;
  width: 335px;
  font-weight: 400;
  line-height: 34px;
  position: relative;
}

.in-this-issue-weve {
  letter-spacing: 0;
  width: 335px;
  line-height: 20px;
  position: relative;
}

.table-of-contents {
  background-color: var(--colorwhite100);
  border-radius: 8px;
  flex-direction: column;
  align-items: flex-start;
  gap: 12px;
  width: 335px;
  padding: 16px 24px;
  display: flex;
  position: absolute;
  top: 293px;
  left: 20px;
  box-shadow: 0 0 20px #0000000d;
}

.in-this-issue {
  color: var(--colorresmedbrandpalettegray900);
  font-family: var(--font-family-sf_pro_text-semibold);
  font-size: var(--font-size-xs);
  letter-spacing: -.2px;
  white-space: nowrap;
  width: -moz-fit-content;
  width: fit-content;
  margin-top: -1px;
  font-weight: 400;
  line-height: 20px;
  position: relative;
}

.table-of-contents-item {
  letter-spacing: 0;
  white-space: nowrap;
  width: -moz-fit-content;
  width: fit-content;
  line-height: 20px;
  position: relative;
}

.getting-started-with-my-air {
  cursor: pointer;
  letter-spacing: 0;
  white-space: nowrap;
  width: -moz-fit-content;
  width: fit-content;
  line-height: 20px;
  position: relative;
}

.tips-for-building-yo {
  letter-spacing: 0;
  width: 288px;
  margin-right: -1px;
  line-height: 20px;
  position: relative;
}

.frame-250 {
  flex-direction: column;
  align-items: flex-start;
  gap: 16px;
  width: 335px;
  margin-top: 48px;
  display: flex;
  position: relative;
}

.frame-230 {
  flex-direction: column;
  flex: none;
  align-items: flex-start;
  gap: 16px;
  display: inline-flex;
  position: relative;
}

.frame-226 {
  background-color: var(--colorwhite100);
  cursor: pointer;
  border-radius: 10px;
  flex: none;
  justify-content: space-between;
  align-items: center;
  width: 335px;
  padding: 12px 12px 12px 32px;
  display: flex;
  position: relative;
  box-shadow: 0 0 20px #0000000d;
}

.air-fit-n20 {
  letter-spacing: .38px;
  white-space: nowrap;
  width: -moz-fit-content;
  width: fit-content;
  line-height: 25px;
  position: relative;
}

.air-fit-n20-1 {
  width: 130px;
  height: 100px;
  position: relative;
}

.what-is-cpap {
  flex-direction: column;
  align-items: flex-start;
  gap: 30px;
  width: 335px;
  margin-top: 48px;
  display: flex;
  position: relative;
}

.adapting-to-cpap {
  flex-direction: column;
  align-items: center;
  gap: 16px;
  margin-top: 48px;
  display: inline-flex;
  position: relative;
}

.air-touch-n20-outsid {
  width: 375px;
  height: 250px;
  position: relative;
}

.frame-199 {
  flex-direction: column;
  flex: none;
  align-items: flex-start;
  gap: 16px;
  width: 335px;
  display: flex;
  position: relative;
}

.frame-200 {
  flex-direction: column;
  flex: none;
  align-self: stretch;
  align-items: flex-start;
  gap: 16px;
  width: 100%;
  display: flex;
  position: relative;
}

.ing {
  letter-spacing: .38px;
  align-self: stretch;
  margin-top: -1px;
  line-height: 25px;
  position: relative;
}

.adapting-to-cpap-the, .it-may-feel-awkward {
  letter-spacing: 0;
  white-space: initial;
  align-self: stretch;
  line-height: 20px;
  position: relative;
}

.button {
  cursor: pointer;
  flex: none;
  justify-content: center;
  align-items: center;
  gap: 4px;
  width: 335px;
  padding: 10px 12px 10px 0;
  display: flex;
  position: relative;
}

.read {
  letter-spacing: .1px;
  flex: 1;
  margin-top: -1px;
  line-height: 20px;
  position: relative;
}

.chevron, .chevron-1, .iconsplay_arrow {
  width: 16px;
  height: 16px;
  position: relative;
}

.tip-1-practice-make {
  color: var(--colorresmedbrandpalettegray900);
  font-family: var(--font-family-undefined-regular);
  font-size: var(--font-size-m);
  letter-spacing: 0;
  align-self: stretch;
  font-weight: 400;
  line-height: 17px;
  position: relative;
}

.x13 .button-1 {
  color: var(--colorresmedbrandpalette005500);
}

.button-1-13 {
  cursor: pointer;
  flex: none;
  justify-content: center;
  align-items: center;
  gap: 4px;
  width: 111px;
  padding: 10px 12px 10px 0;
  display: flex;
  position: relative;
}

.x13 .frame-219 {
  border: 1px solid;
  border-color: var(--palettesbluesblue-300);
  background-color: #fffffff2;
  border-radius: 8px;
  align-items: center;
  gap: 4px;
  padding: 6px 8px;
  display: inline-flex;
  position: absolute;
  top: 16px;
  left: 279px;
  box-shadow: 0 0 20px #00000040;
}

.guide {
  flex: none;
  position: relative;
}

.article {
  color: var(--palettesbluesblue-300);
  font-family: var(--font-family-sf_pro_text-semibold);
  font-size: var(--font-size-xxs);
  letter-spacing: 0;
  white-space: nowrap;
  width: -moz-fit-content;
  width: fit-content;
  margin-top: -1px;
  font-weight: 400;
  line-height: 16px;
  position: relative;
}

.video {
  flex-direction: column;
  align-items: center;
  gap: 24px;
  width: 375px;
  margin-top: 80px;
  display: flex;
  position: relative;
}

.dtc_-mask-refresh202 {
  object-fit: cover;
  width: 375px;
  height: 251px;
  position: relative;
}

.frame-204 {
  flex-direction: column;
  flex: none;
  align-items: flex-start;
  gap: 24px;
  width: 335px;
  display: flex;
  position: relative;
}

.frame-198 {
  flex-direction: column;
  flex: none;
  align-self: stretch;
  align-items: flex-start;
  gap: 20px;
  width: 100%;
  display: flex;
  position: relative;
}

.x13 .frame-220 {
  background-color: var(--colorwhite100);
  border: 1px solid;
  border-color: var(--palettesdark-orangeres-dark-orange-400);
  opacity: .95;
  border-radius: 8px;
  align-items: center;
  gap: 4px;
  padding: 6px 8px;
  display: inline-flex;
  position: absolute;
  top: 16px;
  left: 300px;
  box-shadow: 0 0 20px #00000040;
}

.video-1 {
  color: var(--palettesdark-orangeres-dark-orange-400);
  font-family: var(--font-family-sf_pro_text-semibold);
  font-size: var(--font-size-xxs);
  letter-spacing: 0;
  white-space: nowrap;
  width: -moz-fit-content;
  width: fit-content;
  margin-top: -1px;
  font-weight: 400;
  line-height: 16px;
  position: relative;
}

.frame-226-1 {
  flex-direction: column;
  align-items: flex-start;
  gap: 42px;
  width: 335px;
  margin-top: 48px;
  display: flex;
  position: relative;
}

.sign-off-rating {
  flex-direction: column;
  flex: none;
  align-self: stretch;
  align-items: flex-start;
  gap: 52px;
  width: 100%;
  display: flex;
  position: relative;
}

.most-people-who-use {
  letter-spacing: 0;
  align-self: stretch;
  margin-top: -1px;
  line-height: 20px;
  position: relative;
}

.frame-205 {
  flex-direction: column;
  align-items: flex-start;
  gap: 42px;
  width: 335px;
  margin-top: 138px;
  display: flex;
  position: relative;
}

.sign-off {
  width: 470px;
  height: 48px;
  margin-right: -135px;
  position: relative;
}

.sleep-well-the-my-air-team {
  color: var(--colorresmedbrandpalettegray900);
  font-family: var(--font-family-helvetica_neue-regular);
  font-size: var(--font-size-s);
  letter-spacing: -.16px;
  width: 470px;
  font-weight: 400;
  line-height: 24px;
  position: absolute;
  top: -1px;
  left: 0;
}

.frame-225 {
  flex-direction: column;
  align-items: center;
  gap: 32px;
  margin-top: 48px;
  display: inline-flex;
  position: relative;
}

.rectangle-14 {
  background-color: var(--palettesgreysres-grey-200);
  width: 335px;
  height: 1px;
  position: relative;
}

.frame-209 {
  flex-direction: column;
  flex: none;
  align-items: center;
  gap: 16px;
  display: inline-flex;
  position: relative;
}

.sources {
  color: var(--black);
  font-family: var(--font-family-open_sans);
  font-size: var(--font-size-s);
  letter-spacing: 0;
  width: -moz-fit-content;
  width: fit-content;
  margin-top: -1px;
  font-weight: 600;
  line-height: normal;
  position: relative;
}

.frame-22 {
  background-color: var(--colorwhite100);
  border-radius: 10px;
  flex: none;
  justify-content: space-between;
  align-items: center;
  width: 335px;
  padding: 12px 12px 12px 32px;
  display: flex;
  position: relative;
  box-shadow: 0 0 20px #0000000d;
}

.air-fit-f20, .quattro-air {
  letter-spacing: .38px;
  white-space: nowrap;
  width: -moz-fit-content;
  width: fit-content;
  line-height: 25px;
  position: relative;
}

.air-fit-f20-1, .quattro-air-1 {
  width: 130.19px;
  height: 100px;
  position: relative;
}

.frame-19 {
  flex-direction: column;
  flex: none;
  align-self: stretch;
  align-items: flex-start;
  gap: 16px;
  width: 100%;
  display: flex;
  position: relative;
}

.learn-more-about-your-mask, .getting-started-with-my-air-1 {
  letter-spacing: .38px;
  align-self: stretch;
  margin-top: -1px;
  line-height: 25px;
  position: relative;
}

.x13 .x-your {
  letter-spacing: 0;
  white-space: initial;
  align-self: stretch;
  line-height: 20px;
  position: relative;
}

.x1-2-mask-selected-n20 {
  background-color: var(--colorwhite100);
  border: 1px;
  flex-direction: column;
  align-items: center;
  width: 375px;
  padding: 16px 12px;
  display: flex;
  position: relative;
}

.chevron.over.ride {
  width: 26px;
  height: 24px;
  position: relative;
}

.vector-4 {
  width: 10px;
  height: 18px;
  position: absolute;
  top: 3px;
  left: 7px;
}

.navbarback {
  align-self: flex-start;
  align-items: center;
  gap: 1px;
  display: inline-flex;
  position: relative;
}

.x12-selected .title {
  color: var(--colorresmedbrandpalettegray900);
  font-family: var(--font-family-sf_pro_display-regular);
  font-size: var(--font-size-xxxxl);
  letter-spacing: .36px;
  text-align: center;
  width: 335px;
  margin-top: 7px;
  font-weight: 400;
  line-height: 34px;
}

.x12-selected .air-fit-n20 {
  width: 205px;
  height: 158px;
  margin-top: 24px;
}

.the-air-fit-n20-mask {
  letter-spacing: 0;
  width: 335px;
  min-height: 140px;
  margin-top: 24px;
  line-height: 20px;
}

.mask-fit {
  letter-spacing: .38px;
  white-space: nowrap;
  align-self: flex-start;
  margin-top: 48px;
  margin-left: 8px;
  line-height: 25px;
}

.cleaning {
  letter-spacing: .38px;
  white-space: nowrap;
  align-self: flex-start;
  margin-top: 48px;
  line-height: 25px;
}

.frame-247 {
  flex-direction: column;
  align-items: flex-start;
  gap: 16px;
  margin-top: 48px;
  display: inline-flex;
  position: relative;
}

.fa-qs {
  letter-spacing: .38px;
  white-space: nowrap;
  width: -moz-fit-content;
  width: fit-content;
  margin-top: -1px;
  line-height: 25px;
  position: relative;
}

.if-you-have-further {
  color: #0000;
  font-family: var(--font-family-sf_pro_text-regular);
  font-size: var(--font-size-xs);
  letter-spacing: 0;
  width: 335px;
  min-height: 40px;
  margin-top: 47px;
  font-weight: 400;
  line-height: 20px;
}

.span1 {
  color: var(--colorresmedbrandpalette005500);
  text-decoration: underline;
}

.button {
  cursor: pointer;
  justify-content: center;
  align-items: center;
  gap: 4px;
  margin-top: 49px;
  margin-left: 1px;
  padding: 10px 12px;
  display: inline-flex;
  position: relative;
}

.place {
  color: var(--colorresmedbrandpalette005500);
  font-family: var(--font-family-sf_pro_text-semibold);
  font-size: var(--font-size-s);
  letter-spacing: .1px;
  text-align: center;
  white-space: nowrap;
  width: -moz-fit-content;
  width: fit-content;
  margin-top: -1px;
  font-weight: 400;
  line-height: 20px;
  position: relative;
}

.x12-selected .gradient-card-0, .x12-selected .gradient-card-2 {
  margin-top: 24px;
}

.gradient-card {
  background: linear-gradient(#643695 8.85%, #1788c3 100%);
  border-radius: 12px;
  flex-direction: column;
  align-items: flex-start;
  gap: 16px;
  width: 335px;
  margin-top: 17px;
  padding: 24px 16px;
  display: flex;
  position: relative;
}

.gradient-card.gradient-card-1 {
  background: linear-gradient(#b72755 8.85%, #55489d 100%);
  margin-top: 16px;
}

.gradient-card.gradient-card-2 {
  background: linear-gradient(#4156a6 8.85%, #009bdf 100%);
}

.gradient-card.gradient-card-3 {
  background: linear-gradient(#643695 11.71%, #4156a6 56.99%);
  margin-top: 16px;
}

.gradient-card .button-1 {
  background-color: #fff;
}

.gradient-card .button-2 {
  color: #000;
}

.actions {
  flex: none;
  align-self: stretch;
  align-items: center;
  gap: 8px;
  width: 100%;
  display: flex;
  position: relative;
}

.button-3 {
  flex: none;
  justify-content: center;
  align-items: center;
  gap: 4px;
  padding: 10px 12px;
  display: inline-flex;
  position: relative;
}

.text {
  flex-direction: column;
  flex: none;
  align-self: stretch;
  align-items: flex-start;
  gap: 8px;
  width: 100%;
  display: flex;
  position: relative;
}

.title-2 {
  letter-spacing: .35px;
  align-self: stretch;
  margin-top: -1px;
  line-height: 28px;
  position: relative;
}

.navbaraction {
  flex: none;
  align-items: flex-start;
  gap: 3px;
  height: 20px;
  display: inline-flex;
  position: relative;
}

.action {
  color: var(--textbody);
  font-family: var(--font-family-sf_pro_text-regular);
  font-size: var(--font-size-s);
  letter-spacing: 0;
  white-space: nowrap;
  width: -moz-fit-content;
  width: fit-content;
  margin-top: 0;
  font-weight: 400;
  line-height: normal;
  position: relative;
}

.x1-2-expanded {
  background-color: var(--colorwhite100);
  border: 1px;
  flex-direction: column;
  align-items: center;
  gap: 48px;
  width: 375px;
  display: flex;
}

.x12 .frame-199-1 {
  margin-bottom: 0;
}

.overlap-group-2 {
  width: 375px;
  padding-top: 320px;
  padding-left: 20px;
  position: relative;
}

.rectangle-400 {
  width: 375px;
  height: 462px;
  position: absolute;
  top: 0;
  left: 0;
}

.header {
  background-color: #0000;
  flex-direction: column;
  align-items: flex-start;
  gap: 16px;
  display: inline-flex;
  top: 64px;
  left: 20px;
}

.res-med-logo {
  width: 60px;
  height: 42.64px;
  position: relative;
}

.congratulations-you {
  color: var(--texttitles);
  font-family: var(--font-family-sf_pro_display-regular);
  font-size: var(--font-size-xl);
  letter-spacing: .36px;
  width: 335px;
  font-weight: 400;
  line-height: 34px;
  position: relative;
}

.while-you-wait-for-y {
  letter-spacing: 0;
  width: 335px;
  line-height: 20px;
  position: relative;
}

.table-of-contents {
  background-color: var(--colorwhite100);
  z-index: 100;
  border-radius: 8px;
  flex-direction: column;
  align-items: flex-start;
  gap: 12px;
  width: 335px;
  margin-top: 48px;
  padding: 16px 24px;
  display: flex;
  position: sticky;
  top: 367px;
  left: 20px;
  box-shadow: 0 0 20px #0000000d;
}

.in-this-issue {
  color: var(--texttitles);
  font-family: var(--font-family-sf_pro_text-semibold);
  font-size: var(--font-size-xs);
  letter-spacing: -.2px;
  white-space: nowrap;
  width: -moz-fit-content;
  width: fit-content;
  margin-top: -1px;
  font-weight: 400;
  line-height: 20px;
  position: relative;
}

.x-your {
  letter-spacing: 0;
  white-space: nowrap;
  width: -moz-fit-content;
  width: fit-content;
  line-height: 20px;
  position: relative;
}

.patient-story-using {
  letter-spacing: 0;
  white-space: nowrap;
  width: -moz-fit-content;
  width: fit-content;
  margin-right: -8px;
  line-height: 20px;
  position: relative;
}

.video {
  flex-direction: column;
  align-items: center;
  gap: 24px;
  width: 375px;
  padding: 0 20px;
  display: flex;
  position: relative;
}

.learn-more-about-your-cpap-machine, .learn-more-about-your-mask-1, .adapting-to-a-cpap-machine, .patient-story-using-1 {
  letter-spacing: .38px;
  align-self: stretch;
  margin-top: -1px;
  line-height: 25px;
  position: relative;
}

.frame-204 {
  flex-direction: column;
  flex: none;
  align-items: flex-start;
  gap: 24px;
  width: 335px;
  display: flex;
  position: relative;
}

.frame-198 {
  flex-direction: column;
  flex: none;
  align-self: stretch;
  align-items: flex-start;
  gap: 20px;
  width: 100%;
  display: flex;
  position: relative;
}

.your-cpap-machine-wi {
  letter-spacing: 0;
  word-break: break-word;
  white-space: normal;
  align-self: stretch;
  margin-top: -1px;
  line-height: 20px;
  position: relative;
}

.learn-more-about-your-mask {
  flex-direction: column;
  align-items: flex-start;
  gap: 16px;
  display: inline-flex;
  position: relative;
}

.frame {
  flex-direction: column;
  flex: none;
  align-self: stretch;
  align-items: flex-start;
  gap: 16px;
  width: 100%;
  display: flex;
  position: relative;
}

.select-the-mask-you, .starting-sleep-apnoe {
  letter-spacing: 0;
  align-self: stretch;
  line-height: 20px;
  position: relative;
}

.frame-230 {
  flex-direction: column;
  flex: none;
  align-items: flex-start;
  gap: 16px;
  display: inline-flex;
  position: relative;
}

.frame-226 {
  background-color: var(--colorwhite100);
  cursor: pointer;
  border-radius: 10px;
  flex: none;
  justify-content: space-between;
  align-items: center;
  width: 335px;
  padding: 12px 12px 12px 32px;
  display: flex;
  position: relative;
  box-shadow: 0 0 20px #0000000d;
}

.air-fit-n20 {
  letter-spacing: .38px;
  white-space: nowrap;
  width: -moz-fit-content;
  width: fit-content;
  line-height: 25px;
  position: relative;
}

.air-fit-n20-1 {
  width: 130px;
  height: 100px;
  position: relative;
}

.x1-2-expanded-item {
  flex-direction: column;
  align-items: center;
  gap: 24px;
  display: inline-flex;
  position: relative;
}

.air-touch-n20-outsid {
  object-fit: cover;
  width: 375px;
  height: 250px;
  position: relative;
}

.frame-199 {
  flex-direction: column;
  flex: none;
  align-items: flex-start;
  gap: 16px;
  width: 335px;
  display: flex;
  position: relative;
}

.frame-200 {
  flex-direction: column;
  flex: none;
  align-self: stretch;
  align-items: flex-start;
  gap: 16px;
  width: 100%;
  display: flex;
  position: relative;
}

.button {
  cursor: pointer;
  flex: none;
  justify-content: center;
  align-items: center;
  gap: 4px;
  padding: 10px 12px 10px 0;
  display: inline-flex;
  position: relative;
}

.read-less {
  letter-spacing: .1px;
  text-align: center;
  white-space: nowrap;
  width: -moz-fit-content;
  width: fit-content;
  margin-top: -1px;
  line-height: 20px;
  position: relative;
}

.dtc_-mask-refresh2 {
  object-fit: cover;
  width: 283px;
  height: 142px;
  position: relative;
}

.chevron {
  width: 16px;
  height: 16px;
  position: relative;
}

.set-up-your-cpap-equ {
  color: var(--texttitles);
  font-family: var(--font-family-undefined-regular);
  font-size: var(--font-size-m);
  letter-spacing: 0;
  align-self: stretch;
  font-weight: 400;
  line-height: 17px;
  position: relative;
}

.frame-219-2 {
  background-color: var(--white);
  border: 1px solid;
  border-color: var(--palettesbluesblue-300);
  border-radius: 8px;
  align-items: center;
  gap: 4px;
  padding: 6px 8px;
  display: inline-flex;
  position: absolute;
  top: 16px;
  left: 279px;
  box-shadow: 0 0 20px #00000040;
}

.guide {
  flex: none;
  position: relative;
}

.article {
  color: var(--palettesbluesblue-300);
  font-family: var(--font-family-sf_pro_text-semibold);
  font-size: var(--font-size-xxs);
  letter-spacing: 0;
  white-space: nowrap;
  width: -moz-fit-content;
  width: fit-content;
  margin-top: -1px;
  font-weight: 400;
  line-height: 16px;
  position: relative;
}

.air-touch-n20-outsid-1 {
  width: 375px;
  height: 250px;
  position: relative;
}

.frame-199-1 {
  flex-direction: column;
  flex: none;
  align-items: flex-start;
  gap: 24px;
  width: 335px;
  margin-bottom: 49px;
  display: flex;
  position: relative;
}

.res-meds-patient-eng {
  color: #0000;
  font-family: var(--font-family-sf_pro_text-regular);
  font-size: var(--font-size-xs);
  letter-spacing: 0;
  align-self: stretch;
  font-weight: 400;
  line-height: 20px;
  position: relative;
}

.frame-219-1 {
  background-color: var(--white);
  border: 1px solid;
  border-color: var(--palettespurpleres-purple-500);
  border-radius: 8px;
  align-items: center;
  gap: 4px;
  padding: 6px 8px;
  display: inline-flex;
  position: absolute;
  top: 16px;
  left: 243px;
  box-shadow: 0 0 20px #00000040;
}

.patient-story {
  color: var(--palettespurpleres-purple-500);
  font-family: var(--font-family-sf_pro_text-semibold);
  font-size: var(--font-size-xxs);
  letter-spacing: 0;
  white-space: nowrap;
  width: -moz-fit-content;
  width: fit-content;
  margin-top: -1px;
  font-weight: 400;
  line-height: 16px;
  position: relative;
}

.x12 .align-items-start {
  flex-direction: column;
  align-items: start;
  gap: 24px;
  display: flex;
}

.break-word-flex {
  word-break: break-word;
  white-space: break-spaces;
}

.x1-1-expanded {
  background-color: var(--colorwhite100);
  border: 1px;
  flex-direction: column;
  align-items: center;
  width: 375px;
  display: flex;
}

.overlap-group {
  width: 375px;
  position: relative;
}

.rectangle-395 {
  width: 375px;
  height: 407px;
  position: absolute;
  top: 0;
  left: 0;
}

.frame-224 {
  flex-direction: column;
  align-items: center;
  gap: 48px;
  width: 375px;
  margin-top: 64px;
  display: flex;
  top: 64px;
  left: 0;
}

.frame-211 {
  flex-direction: column;
  flex: none;
  align-items: flex-start;
  gap: 16px;
  display: inline-flex;
  position: relative;
}

.res-med-logo {
  width: 60px;
  height: 42.64px;
  position: relative;
}

.title {
  color: var(--texttitles);
  font-family: var(--font-family-sf_pro_display-regular);
  font-size: var(--font-size-xl);
  letter-spacing: .36px;
  text-align: start;
  width: 335px;
  font-weight: 400;
  line-height: 34px;
  position: relative;
}

.well-be-sending-you {
  letter-spacing: 0;
  width: 335px;
  line-height: 20px;
  position: relative;
}

.frame-212 {
  background-color: var(--colorwhite100);
  border-radius: 8px;
  flex-direction: column;
  flex: none;
  align-items: flex-start;
  gap: 12px;
  width: 335px;
  padding: 16px 24px;
  display: flex;
  position: relative;
  box-shadow: 0 0 20px #0000000d;
}

.in-this-issue {
  color: var(--texttitles);
  font-family: var(--font-family-sf_pro_text-semibold);
  font-size: var(--font-size-xs);
  letter-spacing: -.2px;
  white-space: nowrap;
  width: -moz-fit-content;
  width: fit-content;
  margin-top: -1px;
  font-weight: 400;
  line-height: 20px;
  position: relative;
}

.frame-212-item {
  letter-spacing: 0;
  white-space: nowrap;
  width: -moz-fit-content;
  width: fit-content;
  line-height: 20px;
  position: relative;
}

.frame-203 {
  flex-direction: column;
  flex: none;
  align-items: flex-start;
  gap: 30px;
  width: 335px;
  display: flex;
  position: relative;
}

.frame {
  flex-direction: column;
  flex: none;
  align-self: stretch;
  align-items: flex-start;
  gap: 16px;
  width: 100%;
  display: flex;
  position: relative;
}

.what {
  letter-spacing: .38px;
  white-space: nowrap;
  align-self: stretch;
  margin-top: -1px;
  line-height: 25px;
  position: relative;
}

.new-patients-often-s, .it-took-some-time-fo, .name {
  letter-spacing: 0;
  align-self: stretch;
  line-height: 20px;
  position: relative;
}

.frame-222 {
  flex-direction: column;
  flex: none;
  align-items: center;
  gap: 24px;
  width: 375px;
  display: flex;
  position: relative;
}

.dtc_-mask-refresh202 {
  object-fit: cover;
  width: 375px;
  height: 251px;
  position: relative;
}

.frame-1 {
  flex-direction: column;
  flex: none;
  align-items: flex-start;
  gap: 24px;
  width: 335px;
  display: flex;
  position: relative;
}

.frame-220 {
  background-color: var(--colorwhite100);
  border: 1px solid;
  border-color: var(--palettesdark-orangeres-dark-orange-400);
  opacity: .95;
  border-radius: 8px;
  align-items: center;
  gap: 4px;
  padding: 6px 8px;
  display: inline-flex;
  position: absolute;
  top: 16px;
  left: 286px;
  box-shadow: 0 0 20px #00000040;
}

.iconsplay_arrow, .chevron, .chevron-1 {
  width: 16px;
  height: 16px;
  position: relative;
}

.video {
  color: var(--palettesdark-orangeres-dark-orange-400);
  font-family: var(--font-family-sf_pro_text-semibold);
  font-size: var(--font-size-xxs);
  letter-spacing: 0;
  white-space: nowrap;
  width: -moz-fit-content;
  width: fit-content;
  margin-top: -1px;
  font-weight: 400;
  line-height: 16px;
  position: relative;
}

.frame-223 {
  flex-direction: column;
  flex: none;
  align-items: center;
  gap: 16px;
  display: inline-flex;
  position: relative;
}

.air-touch-n20-outsid {
  object-fit: cover;
  width: 375px;
  height: 250px;
  position: relative;
}

.patient-story-from {
  letter-spacing: .38px;
  align-self: stretch;
  margin-top: -1px;
  line-height: 25px;
  position: relative;
}

.button {
  cursor: pointer;
  flex: none;
  justify-content: center;
  align-items: center;
  gap: 4px;
  padding: 10px 12px 10px 0;
  display: inline-flex;
  position: relative;
}

.text-button {
  letter-spacing: .1px;
  text-align: center;
  white-space: nowrap;
  width: -moz-fit-content;
  width: fit-content;
  margin-top: -1px;
  line-height: 20px;
  position: relative;
}

.tell-us-about-your-s {
  color: var(--texttitles);
  font-family: var(--font-family-undefined-regular);
  font-size: var(--font-size-m);
  letter-spacing: 0;
  align-self: stretch;
  font-weight: 400;
  line-height: 17px;
  position: relative;
}

.frame-219 {
  border: 1px solid;
  border-color: var(--palettespurpleres-purple-500);
  background-color: #fffffff2;
  border-radius: 8px;
  align-items: center;
  gap: 4px;
  padding: 6px 8px;
  display: inline-flex;
  position: absolute;
  top: 16px;
  left: 243px;
  box-shadow: 0 0 20px #00000040;
}

.patient-story {
  color: var(--palettespurpleres-purple-500);
  font-family: var(--font-family-sf_pro_text-semibold);
  font-size: var(--font-size-xxs);
  letter-spacing: 0;
  white-space: nowrap;
  width: -moz-fit-content;
  width: fit-content;
  margin-top: -1px;
  font-weight: 400;
  line-height: 16px;
  position: relative;
}

.frame-249 {
  flex-direction: column;
  align-items: flex-start;
  gap: 16px;
  margin-top: 48px;
  display: inline-flex;
  position: relative;
}

.air-connect-fa-qs {
  letter-spacing: .38px;
  white-space: nowrap;
  width: -moz-fit-content;
  width: fit-content;
  margin-top: -1px;
  line-height: 25px;
  position: relative;
}

.overlap-group1 {
  width: 375px;
  height: 405px;
  margin-top: 36px;
  position: relative;
}

.sign-off {
  width: 335px;
  height: 48px;
  position: absolute;
  top: 0;
  left: 20px;
}

.frame-225 {
  background-color: #efefef;
  flex-direction: column;
  align-items: center;
  gap: 32px;
  width: 375px;
  padding: 0 20px 32px;
  display: flex;
  position: absolute;
  top: 12px;
  left: 0;
}

.rectangle-14 {
  background-color: var(--palettesgreysres-grey-200);
  width: 335px;
  height: 1px;
  position: relative;
}

.frame-209 {
  flex-direction: column;
  flex: none;
  align-self: stretch;
  align-items: center;
  gap: 16px;
  width: 100%;
  display: flex;
  position: relative;
}

.sources {
  color: var(--black);
  font-family: var(--font-family-sf_pro_text-regular);
  font-size: var(--font-size-m);
  letter-spacing: 0;
  white-space: nowrap;
  align-self: stretch;
  margin-top: -1px;
  font-weight: 400;
  line-height: 25.5px;
  position: relative;
}

.lal-c-strange-c-ba {
  letter-spacing: 0;
  align-self: stretch;
  line-height: 16px;
  position: relative;
}

.span5 {
  text-decoration: underline;
}

ul {
  list-style: auto;
}

.x1 .video {
  margin-top: 1px;
  padding: 0;
}

.frame-2-1 {
  background-color: var(--colorwhite100);
  border-radius: 8px;
  flex-direction: column;
  flex: none;
  align-items: flex-start;
  gap: 8px;
  width: 335px;
  padding: 12px 16px;
  display: flex;
  position: relative;
  box-shadow: 0 0 20px #0000000d;
}

.frame-233 {
  flex: none;
  align-self: stretch;
  align-items: center;
  gap: 8px;
  width: 100%;
  display: flex;
  position: relative;
}

.faq-question {
  letter-spacing: 0;
  white-space: nowrap;
  flex: 1;
  margin-top: -1px;
  line-height: 25.5px;
  position: relative;
}

.chevron-2 {
  width: 20px;
  height: 20px;
  position: relative;
}

.frame-2-1.frame-2-3 .faq-question, .frame-2-1.frame-2-4 .faq-question, .frame-2-1.frame-2 .faq-question {
  white-space: unset;
}

.read-more-text {
  color: var(--text-titles, #212121);
  font-family: var(--font-family-sf_pro_text-regular);
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 20px;
}

ul {
  padding-left: 16px;
  list-style: auto;
}

.iconsperson_outline_24px {
  width: 16px;
  height: 16px;
  position: relative;
}

.icon {
  width: 11px;
  height: 11px;
  position: absolute;
  top: 3px;
  left: 3px;
}

.button-1 {
  background-color: var(--colorresmedbrandpalette005500);
  color: var(--colorresmedbrandpalette005500);
  border: 1px solid;
  border-radius: 30px;
  flex: none;
  justify-content: center;
  align-items: center;
  gap: 8px;
  padding: 10px 24px;
  display: inline-flex;
  position: relative;
}

.start-stop {
  width: 16px;
  height: 16px;
  position: relative;
}

.button-2 {
  color: var(--colorwhite100);
  font-family: var(--font-family-sf_pro_text-semibold);
  font-size: var(--font-size-s);
  letter-spacing: .1px;
  text-align: center;
  white-space: nowrap;
  width: -moz-fit-content;
  width: fit-content;
  margin-top: -1px;
  font-weight: 400;
  line-height: 20px;
  position: relative;
}

.youtube-iframe {
  z-index: 10;
  position: absolute;
  left: -20px;
}

iframe.youtube-iframe-left0 {
  z-index: 10;
  position: absolute;
  left: 0;
}

iframe.youtube-iframe-right36 {
  z-index: 10;
  right: -36px;
  left: initial;
  position: absolute;
}

:root {
  --black: #000;
  --colorresmedbrandpalette005500: #006db7;
  --colorresmedbrandpalettegray900: #212121;
  --colorwhite100: #fff;
  --white: #fff;
  --iconsicon-active: #006db7;
  --palettesdark-orangeres-dark-orange-400: #e0642f;
  --palettesgreysres-grey-200: #e6e6e7;
  --palettesbluesblue-300: #0091f4;
  --palettespurpleres-purple-500: #6f2c91;
  --textbody: #424243;
  --texttitles: #212121;
  --font-size-l: 20px;
  --font-size-m: 17px;
  --font-size-s: 16px;
  --font-size-xl: 28px;
  --font-size-xxl: 20px;
  --font-size-xxxl: 24px;
  --font-size-xxxxl: 28px;
  --font-size-xs: 14px;
  --font-size-xxs: 12px;
  --font-family-sf_pro_display-regular: "SF Pro Display-Regular", Helvetica;
  --font-family-sf_pro_display-semibold: "SF Pro Display-Semibold", Helvetica;
  --font-family-sf_pro_text-regular: "SF Pro Text-Regular", Helvetica;
  --font-family-sf_pro_text-semibold: "SF Pro Text-Semibold", Helvetica;
  --font-family-undefined-regular: "undefined-Regular", Helvetica;
}

.sfprotext-regular-normal-log-cabin-14px {
  color: var(--texttitles);
  font-family: var(--font-family-sf_pro_text-regular);
  font-size: var(--font-size-xs);
  font-style: normal;
  font-weight: 400;
}

.sfprotext-regular-normal-black-12px {
  color: var(--black);
  font-family: var(--font-family-sf_pro_text-regular);
  font-size: var(--font-size-xxs);
  font-style: normal;
  font-weight: 400;
}

.sfprotext-normal-cape-cod-17px {
  color: var(--textbody);
  font-family: var(--font-family-sf_pro_text-semibold);
  font-size: var(--font-size-m);
  font-style: normal;
  font-weight: 400;
}

.sfprotext-regular-normal-science-blue-14px {
  color: var(--colorresmedbrandpalette005500);
  font-family: var(--font-family-sf_pro_text-regular);
  font-size: var(--font-size-xs);
  font-style: normal;
  font-weight: 400;
}

.sfprodisplay-normal-log-cabin-20px {
  color: var(--texttitles);
  font-family: var(--font-family-sf_pro_display-semibold);
  font-size: var(--font-size-l);
  font-style: normal;
  font-weight: 400;
}

.sfprotext-normal-science-blue-16px {
  color: var(--colorresmedbrandpalette005500);
  font-family: var(--font-family-sf_pro_text-semibold);
  font-size: var(--font-size-s);
  font-style: normal;
  font-weight: 400;
}

.sfprodisplay-normal-white-24px {
  color: var(--colorwhite100);
  font-family: var(--font-family-sf_pro_display-semibold);
  font-size: var(--font-size-xxxl);
  font-style: normal;
  font-weight: 400;
}

/*# sourceMappingURL=index.99510671.css.map */
