:root { 
  --black: #000000;
  --colorresmedbrandpalette005500: #006db7;
  --colorresmedbrandpalettegray900: #212121;
  --colorwhite100: #ffffff;
  --white: #ffffff;
  --iconsicon-active: #006db7;
  --palettesdark-orangeres-dark-orange-400: #e0642f;
  --palettesgreysres-grey-200: #e6e6e7;
  --palettesbluesblue-300: #0091f4;
  --palettespurpleres-purple-500: #6f2c91;
  --textbody: #424243;
  --texttitles: #212121;
 
  --font-size-l: 20px;
  --font-size-m: 17px;
  --font-size-s: 16px;
  --font-size-xl: 28px;
  --font-size-xxl: 20px;
  --font-size-xxxl: 24px;
  --font-size-xxxxl: 28px;
  --font-size-xs: 14px;
  --font-size-xxs: 12px;
 
  --font-family-sf_pro_display-regular: "SF Pro Display-Regular", Helvetica;
  --font-family-sf_pro_display-semibold: "SF Pro Display-Semibold", Helvetica;
  --font-family-sf_pro_text-regular: "SF Pro Text-Regular", Helvetica;
  --font-family-sf_pro_text-semibold: "SF Pro Text-Semibold", Helvetica;
  --font-family-undefined-regular: "undefined-Regular", Helvetica;
}
.sfprotext-regular-normal-log-cabin-14px {
  color: var(--texttitles);
  font-family: var(--font-family-sf_pro_text-regular);
  font-size: var(--font-size-xs);
  font-style: normal;
  font-weight: 400;
}

.sfprotext-regular-normal-black-12px {
  color: var(--black);
  font-family: var(--font-family-sf_pro_text-regular);
  font-size: var(--font-size-xxs);
  font-style: normal;
  font-weight: 400;
}

.sfprotext-normal-cape-cod-17px {
  color: var(--textbody);
  font-family: var(--font-family-sf_pro_text-semibold);
  font-size: var(--font-size-m);
  font-style: normal;
  font-weight: 400;
}

.sfprotext-regular-normal-science-blue-14px {
  color: var(--colorresmedbrandpalette005500);
  font-family: var(--font-family-sf_pro_text-regular);
  font-size: var(--font-size-xs);
  font-style: normal;
  font-weight: 400;
}

.sfprodisplay-normal-log-cabin-20px {
  color: var(--texttitles);
  font-family: var(--font-family-sf_pro_display-semibold);
  font-size: var(--font-size-l);
  font-style: normal;
  font-weight: 400;
}

.sfprotext-normal-science-blue-16px {
  color: var(--colorresmedbrandpalette005500);
  font-family: var(--font-family-sf_pro_text-semibold);
  font-size: var(--font-size-s);
  font-style: normal;
  font-weight: 400;
}

.sfprodisplay-normal-white-24px {
  color: var(--colorwhite100);
  font-family: var(--font-family-sf_pro_display-semibold);
  font-size: var(--font-size-xxxl);
  font-style: normal;
  font-weight: 400;
}
