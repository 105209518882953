.frame-2-1 {
  align-items: flex-start;
  background-color: var(--colorwhite100);
  border-radius: 8px;
  box-shadow: 0px 0px 20px #0000000d;
  display: flex;
  flex: 0 0 auto;
  flex-direction: column;
  gap: 8px;
  padding: 12px 16px;
  position: relative;
  width: 335px;
}

.frame-233 {
  align-items: center;
  align-self: stretch;
  display: flex;
  flex: 0 0 auto;
  gap: 8px;
  position: relative;
  width: 100%;
}

.faq-question {
  flex: 1;
  letter-spacing: 0;
  line-height: 25.5px;
  margin-top: -1.00px;
  position: relative;
  white-space: nowrap;
}

.chevron-2 {
  height: 20px;
  position: relative;
  width: 20px;
}

.frame-2-1.frame-2-3 .faq-question,
.frame-2-1.frame-2-4 .faq-question,
.frame-2-1.frame-2 .faq-question {
  white-space: unset;
}

.read-more-text {
  color: var(--text-titles, #212121);
  /* Body/Small Regular */
  font-family: var(--font-family-sf_pro_text-regular);
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 20px;
}
ul {
  list-style-position: outside;
  padding-left: 16px;
  list-style: auto;

}