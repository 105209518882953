.text {
  align-items: flex-start;
  align-self: stretch;
  display: flex;
  flex: 0 0 auto;
  flex-direction: column;
  gap: 8px;
  position: relative;
  width: 100%;
}

.title-2 {
  align-self: stretch;
  letter-spacing: 0.35px;
  line-height: 28px;
  margin-top: -1.00px;
  position: relative;
}

