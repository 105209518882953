.iconsperson_outline_24px {
  height: 16px;
  position: relative;
  width: 16px;
}

.icon {
  height: 11px;
  left: 3px;
  position: absolute;
  top: 3px;
  width: 11px;
}

