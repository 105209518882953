.frame-22 {
  align-items: center;
  background-color: var(--colorwhite100);
  border-radius: 10px;
  box-shadow: 0px 0px 20px #0000000d;
  display: flex;
  flex: 0 0 auto;
  justify-content: space-between;
  padding: 12px 12px 12px 32px;
  position: relative;
  width: 335px;
}

.air-fit-f20,
.quattro-air {
  letter-spacing: 0.38px;
  line-height: 25px;
  position: relative;
  white-space: nowrap;
  width: fit-content;
}

.air-fit-f20-1,
.quattro-air-1 {
  height: 100px;
  position: relative;
  width: 130.19px;
}

