.navbaraction {
  align-items: flex-start;
  display: inline-flex;
  flex: 0 0 auto;
  gap: 3px;
  height: 20px;
  position: relative;
}

.action {
  color: var(--textbody);
  font-family: var(--font-family-sf_pro_text-regular);
  font-size: var(--font-size-s);
  font-weight: 400;
  letter-spacing: 0;
  line-height: normal;
  margin-top: 0.00px;
  position: relative;
  white-space: nowrap;
  width: fit-content;
}

