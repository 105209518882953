.x1-1-expanded {
  align-items: center;
  background-color: var(--colorwhite100);
  border: 1px none;
  display: flex;
  flex-direction: column;
  width: 375px;
}

.overlap-group {
  position: relative;
  width: 375px;
}

.rectangle-395 {
  height: 407px;
  left: 0;
  position: absolute;
  top: 0;
  width: 375px;
}

.frame-224 {
  align-items: center;
  display: flex;
  flex-direction: column;
  gap: 48px;
  left: 0;
  margin-top: 64px;
  /* position: absolute; */
  top: 64px;
  width: 375px;
}

.frame-211 {
  align-items: flex-start;
  display: inline-flex;
  flex: 0 0 auto;
  flex-direction: column;
  gap: 16px;
  position: relative;
}

.res-med-logo {
  height: 42.64px;
  position: relative;
  width: 60px;
}

.title {
  color: var(--texttitles);
  font-family: var(--font-family-sf_pro_display-regular);
  font-size: var(--font-size-xl);
  font-weight: 400;
  letter-spacing: 0.36px;
  line-height: 34px;
  position: relative;
  width: 335px;
  text-align: start;
}

.well-be-sending-you {
  letter-spacing: 0;
  line-height: 20px;
  position: relative;
  width: 335px;
}

.frame-212 {
  align-items: flex-start;
  background-color: var(--colorwhite100);
  border-radius: 8px;
  box-shadow: 0px 0px 20px #0000000d;
  display: flex;
  flex: 0 0 auto;
  flex-direction: column;
  gap: 12px;
  padding: 16px 24px;
  position: relative;
  width: 335px;
}

.in-this-issue {
  color: var(--texttitles);
  font-family: var(--font-family-sf_pro_text-semibold);
  font-size: var(--font-size-xs);
  font-weight: 400;
  letter-spacing: -0.20px;
  line-height: 20px;
  margin-top: -1.00px;
  position: relative;
  white-space: nowrap;
  width: fit-content;
}

.frame-212-item {
  letter-spacing: 0;
  line-height: 20px;
  position: relative;
  white-space: nowrap;
  width: fit-content;
}

.frame-203 {
  align-items: flex-start;
  display: flex;
  flex: 0 0 auto;
  flex-direction: column;
  gap: 30px;
  position: relative;
  width: 335px;
}

.frame {
  align-items: flex-start;
  align-self: stretch;
  display: flex;
  flex: 0 0 auto;
  flex-direction: column;
  gap: 16px;
  position: relative;
  width: 100%;
}

.what {
  align-self: stretch;
  letter-spacing: 0.38px;
  line-height: 25px;
  margin-top: -1.00px;
  position: relative;
  white-space: nowrap;
}

.new-patients-often-s,
.it-took-some-time-fo,
.name {
  align-self: stretch;
  letter-spacing: 0;
  line-height: 20px;
  position: relative;
}

.frame-222 {
  align-items: center;
  display: flex;
  flex: 0 0 auto;
  flex-direction: column;
  gap: 24px;
  position: relative;
  width: 375px;
}

.dtc_-mask-refresh202 {
  height: 251px;
  object-fit: cover;
  position: relative;
  width: 375px;
}

.frame-1 {
  align-items: flex-start;
  display: flex;
  flex: 0 0 auto;
  flex-direction: column;
  gap: 24px;
  position: relative;
  width: 335px;
}

.frame-220 {
  align-items: center;
  background-color: var(--colorwhite100);
  border: 1px solid;
  border-color: var(--palettesdark-orangeres-dark-orange-400);
  border-radius: 8px;
  box-shadow: 0px 0px 20px #00000040;
  display: inline-flex;
  gap: 4px;
  left: 286px;
  opacity: 0.95;
  padding: 6px 8px;
  position: absolute;
  top: 16px;
}

.iconsplay_arrow,
.chevron,
.chevron-1 {
  height: 16px;
  position: relative;
  width: 16px;
}

.video {
  color: var(--palettesdark-orangeres-dark-orange-400);
  font-family: var(--font-family-sf_pro_text-semibold);
  font-size: var(--font-size-xxs);
  font-weight: 400;
  letter-spacing: 0;
  line-height: 16px;
  margin-top: -1.00px;
  position: relative;
  white-space: nowrap;
  width: fit-content;
}

.frame-223 {
  align-items: center;
  display: inline-flex;
  flex: 0 0 auto;
  flex-direction: column;
  gap: 16px;
  position: relative;
}

.air-touch-n20-outsid {
  height: 250px;
  object-fit: cover;
  position: relative;
  width: 375px;
}

.patient-story-from {
  align-self: stretch;
  letter-spacing: 0.38px;
  line-height: 25px;
  margin-top: -1.00px;
  position: relative;
}

.button {
  align-items: center;
  cursor: pointer;
  display: inline-flex;
  flex: 0 0 auto;
  gap: 4px;
  justify-content: center;
  padding: 10px 12px 10px 0px;
  position: relative;
}

.text-button {
  letter-spacing: 0.10px;
  line-height: 20px;
  margin-top: -1.00px;
  position: relative;
  text-align: center;
  white-space: nowrap;
  width: fit-content;
}

.tell-us-about-your-s {
  align-self: stretch;
  color: var(--texttitles);
  font-family: var(--font-family-undefined-regular);
  font-size: var(--font-size-m);
  font-weight: 400;
  letter-spacing: 0;
  line-height: 17px;
  position: relative;
}

.frame-219 {
  align-items: center;
  background-color: #fffffff2;
  border: 1px solid;
  border-color: var(--palettespurpleres-purple-500);
  border-radius: 8px;
  box-shadow: 0px 0px 20px #00000040;
  display: inline-flex;
  gap: 4px;
  left: 243px;
  padding: 6px 8px;
  position: absolute;
  top: 16px;
}

.patient-story {
  color: var(--palettespurpleres-purple-500);
  font-family: var(--font-family-sf_pro_text-semibold);
  font-size: var(--font-size-xxs);
  font-weight: 400;
  letter-spacing: 0;
  line-height: 16px;
  margin-top: -1.00px;
  position: relative;
  white-space: nowrap;
  width: fit-content;
}

.frame-249 {
  align-items: flex-start;
  display: inline-flex;
  flex-direction: column;
  gap: 16px;
  margin-top: 48px;
  position: relative;
}

.air-connect-fa-qs {
  letter-spacing: 0.38px;
  line-height: 25px;
  margin-top: -1.00px;
  position: relative;
  white-space: nowrap;
  width: fit-content;
}

.overlap-group1 {
  height: 405px;
  margin-top: 36px;
  position: relative;
  width: 375px;
}

.sign-off {
  height: 48px;
  left: 20px;
  position: absolute;
  top: 0;
  width: 335px;
}

.frame-225 {
  align-items: center;
  background-color: #efefef;
  display: flex;
  flex-direction: column;
  gap: 32px;
  left: 0;
  padding: 0px 20px 32px;
  position: absolute;
  top: 12px;
  width: 375px;
}

.rectangle-14 {
  background-color: var(--palettesgreysres-grey-200);
  height: 1px;
  position: relative;
  width: 335px;
}

.frame-209 {
  align-items: center;
  align-self: stretch;
  display: flex;
  flex: 0 0 auto;
  flex-direction: column;
  gap: 16px;
  position: relative;
  width: 100%;
}

.sources {
  align-self: stretch;
  color: var(--black);
  font-family: var(--font-family-sf_pro_text-regular);
  font-size: var(--font-size-m);
  font-weight: 400;
  letter-spacing: 0;
  line-height: 25.5px;
  margin-top: -1.00px;
  position: relative;
  white-space: nowrap;
}

.lal-c-strange-c-ba {
  align-self: stretch;
  letter-spacing: 0;
  line-height: 16px;
  position: relative;
}

.span5 {
  text-decoration: underline;
}

ul {
  list-style-position: outside;
  list-style: auto;
}

.x1 .video {
  margin-top: 1px;
  padding: 0;
}