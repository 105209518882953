.button-1 {
  align-items: center;
  background-color: var(--colorresmedbrandpalette005500);
  color: var(--colorresmedbrandpalette005500);
  border: 1px solid;
  border-radius: 30px;
  display: inline-flex;
  flex: 0 0 auto;
  gap: 8px;
  justify-content: center;
  padding: 10px 24px;
  position: relative;
}

.start-stop {
  height: 16px;
  position: relative;
  width: 16px;
}

.button-2 {
  color: var(--colorwhite100);
  font-family: var(--font-family-sf_pro_text-semibold);
  font-size: var(--font-size-s);
  font-weight: 400;
  letter-spacing: 0.10px;
  line-height: 20px;
  margin-top: -1.00px;
  position: relative;
  text-align: center;
  white-space: nowrap;
  width: fit-content;
}

.youtube-iframe {
  position: absolute;
  z-index: 10;
  left: -20px;
}
