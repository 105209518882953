iframe.youtube-iframe-left0 {
    z-index: 10;
    position: absolute;
    left: 0px;
}

iframe.youtube-iframe-right36 {
    z-index: 10;
    position: absolute;
    right: -36px;
    left: initial;
}