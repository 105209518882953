@import url("https://cdnjs.cloudflare.com/ajax/libs/meyer-reset/2.0/reset.min.css");

@font-face {
  font-family: "SF Pro Display-Regular";
  font-style: normal;
  font-weight: 400;
  src: url("static/fonts/SF-Pro-Display-Regular.otf") format("opentype");
}
@font-face {
  font-family: "SF Pro Text-Regular";
  font-style: normal;
  font-weight: 400;
  src: url("static/fonts/FontsFree-Net-SFProText-Regular.ttf") format("truetype");
}
@font-face {
  font-family: "SF Pro Text-Semibold";
  font-style: normal;
  font-weight: 400;
  src: url("static/fonts/FontsFree-Net-SFProText-Semibold.ttf") format("truetype");
}
@font-face {
  font-family: "SF Pro Display-Semibold";
  font-style: normal;
  font-weight: 400;
  src: url("static/fonts/FontsFree-Net-SFProDisplay-Semibold.ttf") format("truetype");
}
@font-face {
  font-family: "undefined-Regular";
  font-style: normal;
  font-weight: 400;
  src: url("static/fonts/Augusta.ttf") format("truetype");
}


.screen a {
  display: contents;
  text-decoration: none;
}

.container-center-horizontal {
  display: flex;
  flex-direction: row;
  justify-content: center;
  pointer-events: none;
  width: 100%;
}

.container-center-horizontal > * {
  flex-shrink: 0;
  pointer-events: auto;
}

* {
  box-sizing: border-box;
}

.see-more-title {
  color: var(--text-titles, #212121);
  /* Body/Large Bold */
  font-family: var(--font-family-sf_pro_text-semibold);
  font-size: 17px;
  font-style: normal;
  font-weight: 600;
  line-height: 25.5px; /* 150% */
}

.see-more-text {
  color: var(--text-titles, #212121);
  /* Body/Small Regular */
  font-family: var(--font-family-sf_pro_text-regular);
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 20px;
}

.see-more-note {
  color: var(--text-titles, #212121);
  font-family: var(--font-family-sf_pro_text-regular);
  font-size: 12px;
  font-style: italic;
  font-weight: 400;
  line-height: 16px;
}

.see-more-text-bold {
  color: var(--text-titles, #212121);
  font-family: var(--font-family-sf_pro_text-semibold);
  font-size: 14px;
  font-style: normal;
  font-weight: 600;
  line-height: 20px;
}